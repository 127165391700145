import { axios } from 'utils/axios';

type CountryAutocompleteItem = {
  id: string;
  name: string;
};
// Todo: rethink why we use an object here instead of a direct function return
export const useCountryApi = (): {
  getAutoCompleteItems: (searchTerm: string) => Promise<CountryAutocompleteItem[]>;
} => {
  const getAutoCompleteItems = async (searchTerm: string): Promise<CountryAutocompleteItem[]> => {
    const result = await axios.get(`/countries/search?searchTerm=${searchTerm.trim()}`);
    return result.data;
  };

  return { getAutoCompleteItems };
};
