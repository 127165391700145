import { EmbeddedEntry } from '@novaheal/types';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { axios } from 'utils/axios';

const getEntry = async (id: string): Promise<EmbeddedEntry | null> => {
  const { data: embeddedEntry } = await axios.get<EmbeddedEntry | null>(`/ctf/entry/${id}`);

  return embeddedEntry;
};

export const useEntry = (id: string): UseQueryResult<EmbeddedEntry, unknown> => {
  return useQuery({
    queryKey: [`entry-${id}`],
    queryFn: () => getEntry(id),
    enabled: !!id,
  });
};
