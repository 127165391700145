import { PaymentInterval } from '@novaheal/types';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { axios } from 'utils/axios';

export type SubscriptionPrice = {
  id: string;
  description: string;
  interval: PaymentInterval;
  price: number;
};

const getSubscriptionPrices = async (): Promise<SubscriptionPrice[]> => {
  const { data: priceData } = await axios.get<SubscriptionPrice[]>('/subscription/prices');

  if (!priceData) {
    throw new Error('No prices found');
  }

  return priceData;
};

export const useSubscriptionPrices = (): UseQueryResult<SubscriptionPrice[], Error> => {
  return useQuery({
    queryKey: ['prices'],
    queryFn: getSubscriptionPrices,
  });
};
