import { axios } from 'utils/axios';
import storage from 'utils/storage';
import { logFirebaseUserFlow } from 'utils/firebaseLogs';
import { EmailLoginCredentials, User, EmailRegisterCredentials } from '@novaheal/types';
import { SessionStorageKey } from 'hooks/useSessionStorage';

export async function loginWithEmail(credentials: EmailLoginCredentials): Promise<User> {
  const response = await axios.post('/auth/login', credentials);
  const { updatedUser, accessToken } = response.data;
  storage.setToken(accessToken);
  logFirebaseUserFlow('login', { ourUserId: updatedUser.id, loginMethod: 'email' }); // named ourUserId, as userId is blocked by ga
  return updatedUser;
}

export type RegisterWithCountry = EmailRegisterCredentials & { countryId?: string };

export async function registerWithEmail(data: RegisterWithCountry): Promise<User> {
  const refId = sessionStorage.getItem(SessionStorageKey.REF_ID);
  const response = await axios.post<User>('/auth/register', {
    ...data,
    acceptedDataPrivacy: data.dataSecurity,
    acceptedAgb: data.agb,
    ...(refId && { referralCode: JSON.parse(refId) }),
  });
  logFirebaseUserFlow('register', { ourUserId: response.data.id, registerMethod: 'email' }); // named ourUserId, as userId is blocked by ga
  return response.data;
}
