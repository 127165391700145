import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';
import Joi from 'joi';
import { CheckboxField } from 'components/common/Form/CheckboxField';
import { Form, InputField } from 'components/common/Form';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { caughtErrorGlobally } from 'utils/axios';
import { ToastVariant } from 'types/Toast';
import { AxiosError } from 'axios';
import { useWelcomePostStep } from 'hooks/api/backend/welcome/useWelcomeStep';
import { SurveyInput, clearSurveyInput } from 'utils/clearSurveyInput';
import { toastContent } from 'config/toast';
import { ErrorStatusType } from 'types/StatusTypes';
import { WelcomeScreenOptions } from './Welcome';

const INPUT_IDENTIFIER = '-input';
const GENERAL_ERROR_IDENTIFIER = 'form';

const surveyInputSchema = Joi.object()
  .pattern(
    Joi.string(),
    [Joi.boolean(), Joi.string().allow('')] // Allows both boolean and string
  )
  .custom((formObject, helpers) => {
    const keys = Object.keys(formObject);
    const hasChecked = keys.some((key) => formObject[key] === true); // At least one checkbox must be checked

    const inputCheckboxFields = keys.filter((key) => key.includes(INPUT_IDENTIFIER));
    const listOfCheckedInputFields = inputCheckboxFields.filter((key) => formObject[key] === true);
    const areAllCheckedInputFieldsFilled = listOfCheckedInputFields.every(
      (key) => formObject[key.replace(INPUT_IDENTIFIER, '')] !== ''
    );

    // ?? The err messages won't show at the moment as we disable the submit anyway
    // ?? this errors can accessed via formState.errors[GENERAL_ERROR_IDENTIFIER]
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    helpers.state.path?.push(GENERAL_ERROR_IDENTIFIER); // state is actually an array, but the type definition is wrong. This enables us to nap the err via errors.form.message
    if (!hasChecked) {
      return helpers.message({ custom: 'At least one checkbox must be checked.' });
    }

    if (!areAllCheckedInputFieldsFilled) {
      return helpers.message({
        custom: 'When "other" is checked, the corresponding input field must be filled out.',
      });
    }

    return formObject; // Everything passed
  }, 'Survey input validation')
  .required();

type Props = WelcomeScreenOptions;

type SurveyOption = {
  label: string;
  type: string;
  id: string;
};

export const ReferralSurvey = ({ ...options }: Props): ReactElement => {
  const { onContinue, stepData } = options;
  const [isLoading, setLoading] = useState(false);
  const toast = useNovaToast();

  const [surveyOptions, setSurveyOptions] = useState<SurveyOption[]>();
  const { mutateAsync: updateSurveyData } = useWelcomePostStep();

  useEffect(() => {
    if (!stepData.currentData.data) return;
    setSurveyOptions(stepData.currentData.data.questions);
  }, [stepData]);

  const handleSubmit = async (values: SurveyInput): Promise<void> => {
    setLoading(true);
    try {
      const onboardingData = await updateSurveyData({
        stepIdentifier: stepData.currentStepIdentifier,
        totalSteps: stepData.totalSteps,
        step: stepData.currentStep,
        data: clearSurveyInput(values),
      });
      onContinue(onboardingData);
    } catch (error) {
      if (caughtErrorGlobally(error as AxiosError)) {
        toast.show(toastContent.error[ErrorStatusType.SERVER_ERROR]);
        return;
      }
      toast.show({
        variant: ToastVariant.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form<SurveyInput, typeof surveyInputSchema>
      onSubmit={handleSubmit}
      schema={surveyInputSchema}
      autoComplete="off"
    >
      {({ register, formState, watch }) => {
        const { isValid, isDirty } = formState;

        return (
          <>
            <Flex direction="column" gap="20px" color="blue.textHeader">
              <Text textStyle="h2">Wie hast du von uns erfahren?</Text>
              {surveyOptions &&
                surveyOptions?.map(({ type, label, id }) => {
                  if (type.toLowerCase() === 'checkbox') {
                    return (
                      <CheckboxField
                        type="boolean"
                        error={formState.errors[id] as FieldError}
                        registration={register(id)}
                        labelElement={<Text textStyle="body.16.reg">{label}</Text>}
                      />
                    );
                  }
                  return (
                    <Flex direction="column" gap="0px">
                      <CheckboxField
                        type="boolean"
                        error={formState.errors[`${id}${INPUT_IDENTIFIER}`] as FieldError}
                        registration={register(`${id}${INPUT_IDENTIFIER}`)}
                        labelElement={<Text textStyle="body.16.reg">{label}</Text>}
                      />

                      {watch(`${id}${INPUT_IDENTIFIER}`) && (
                        <InputField
                          type="text"
                          error={formState.errors[`${id}`] as FieldError}
                          registration={register(`${id}`)}
                          placeholder="Anderes"
                        />
                      )}
                    </Flex>
                  );
                })}
            </Flex>

            <Button
              variant="primary"
              type="submit"
              isLoading={isLoading}
              isDisabled={!isValid || !isDirty}
              loadingText="Speichern"
              mt="40px"
              w="full"
              py="10px"
              translate="no"
            >
              Jetzt loslegen
            </Button>
          </>
        );
      }}
    </Form>
  );
};
