import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

type Props = IconProps;

export const GlobeIcon = (props: Props): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM21.1712 14.8457C21.4499 13.9465 21.6 12.9908 21.6 12C21.6 11.1483 21.4891 10.3224 21.2809 9.53618H16.6033C16.8325 11.1807 16.8713 12.9653 16.5962 14.8457H21.1712ZM15.1773 14.8457C15.4769 12.9599 15.4338 11.1736 15.1892 9.53618H8.89665C8.63013 11.1768 8.55907 12.9644 8.8248 14.8457H15.1773ZM7.40914 14.8457H2.82877C2.55009 13.9465 2.4 12.9908 2.4 12C2.4 11.1483 2.51092 10.3224 2.7191 9.53618H7.47994C7.23005 11.183 7.16509 12.9687 7.40914 14.8457ZM9.17702 8.13618H14.9258C14.3972 5.8058 13.5043 3.84648 12.7302 2.42735C12.4892 2.40923 12.2457 2.4 12 2.4C11.8021 2.4 11.6057 2.40599 11.4108 2.41779C10.6387 3.83293 9.73277 5.79991 9.17702 8.13618ZM9.69715 2.67798C8.97912 4.12478 8.21937 5.98568 7.74174 8.13618H3.20925C4.39554 5.44109 6.78487 3.395 9.69715 2.67798ZM7.64698 16.2457C7.98884 17.8716 8.57447 19.5546 9.47934 21.2657C6.80384 20.5395 4.59253 18.6855 3.38748 16.2457H7.64698ZM11.2597 21.5719C10.1684 19.7573 9.47672 17.9692 9.08311 16.2457H14.8941C14.4706 17.966 13.7479 19.7557 12.6264 21.5799C12.4193 21.5932 12.2105 21.6 12 21.6C11.7509 21.6 11.504 21.5905 11.2597 21.5719ZM20.7908 8.13618H16.3577C15.9075 6.00673 15.1693 4.16277 14.4575 2.71742C17.2996 3.46785 19.6255 5.48894 20.7908 8.13618ZM20.6125 16.2457C19.3907 18.7194 17.1344 20.591 14.4087 21.2953C15.3483 19.5692 15.9655 17.8769 16.3354 16.2457H20.6125Z"
        fill="currentColor"
      />
    </Icon>
  );
};
