import { ReactElement, useEffect, useMemo, useState } from 'react';
import { PageContext } from 'components/common/PageContext';
import { VStack, Text, IconButton, Spinner, Box, Grid } from '@chakra-ui/react';
import { Column, Table } from 'components/common/Table/Table';
import { useUserManagement, useUsers, useKickUser } from 'hooks/api/backend/managament/getB2BUsers';
import { DeleteIcon } from '@chakra-ui/icons';
import { useModalService } from 'hooks/useModalService';
import { ModalVariant } from 'types/Modal';
import { Pagination } from 'components/common/Table/Pagination';
import { Filters } from 'components/common/Table/Filters';

export const B2BDashboard = (): ReactElement => {
  const { data: dashboardProfile } = useUserManagement();
  const modalService = useModalService();

  const allSchools = useMemo(
    () => dashboardProfile?.allSchools || [],
    [dashboardProfile?.allSchools]
  );
  const isOnlyOneSchool = allSchools.length === 1;
  const schoolRolesWithVoucher = dashboardProfile?.schoolRolesWithVoucher || {};

  const [selectedSchool, setSelectedSchool] = useState<string>(
    isOnlyOneSchool ? allSchools[0].id : ''
  );
  const [selectedVoucher, setSelectedVoucher] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    data: usersData,
    refetch,
    isLoading,
  } = useUsers(selectedSchool, selectedVoucher, currentPage);
  const kickUserMutation = useKickUser(refetch);

  // Ensure the first school is selected automatically
  useEffect(() => {
    if (isOnlyOneSchool && allSchools.length === 1) {
      setSelectedSchool(allSchools[0].id);
    }
  }, [isOnlyOneSchool, allSchools]);

  useEffect(() => {
    if (selectedSchool) {
      refetch();
    }
  }, [selectedSchool, selectedVoucher, refetch]);

  const handleSchoolChange = (schoolId: string): void => {
    setSelectedSchool(schoolId);
    setSelectedVoucher(''); // Reset voucher when school changes
    resetPagination();
  };

  const handleVoucherChange = (voucherId: string): void => {
    setSelectedVoucher(voucherId);
    resetPagination();
  };

  const handleKickUser = (userId: string, voucherId: string, schoolId: string): void => {
    modalService.show(ModalVariant.CONFIRMATION, {
      shouldGoBack: false,
      onConfirm: () => kickUserMutation.mutate({ userId, voucherId, b2bSchoolId: schoolId }),
      target: undefined,
    });
  };

  const resetPagination = (): void => {
    setCurrentPage(1);
  };

  const handlePageChange = (newPage: number): void => {
    setCurrentPage(newPage);
  };

  const usersColumns: Column[] = [
    { header: 'Vorname', accessor: 'firstName' },
    { header: 'Email', accessor: 'email' },
    { header: 'Ausbildungsstatus', accessor: 'educationStatus' },
    { header: 'Organisationslizenz-ID', accessor: 'voucherShortCode' },
    { header: 'Aktivierungsdatum', accessor: 'subscriptionUpdatedAt' },
    { header: 'Aktion', accessor: 'actions' },
  ];

  const mappedUsers = usersData?.data?.map((mappingUser) => ({
    ...mappingUser,
    actions: (
      <IconButton
        aria-label="kick"
        padding={0}
        margin={0}
        icon={<DeleteIcon />}
        onClick={() =>
          handleKickUser(mappingUser.id, mappingUser.voucherId, mappingUser.b2bSchoolId)
        }
      />
    ),
  }));

  return (
    <PageContext title="Nutzerverwaltung" hasBackButton headTitle="Nutzerverwaltung">
      <Box height="100%" minH="100%" flexDirection="column" maxWidth="100%" flexGrow={1}>
        <VStack
          bg="bg.light"
          align="left"
          p={{ base: '10px 28px', md: '20px' }}
          margin={{ md: '40px 0' }}
          spacing={{ base: '32px', md: '40px' }}
          width="100%"
          borderRadius={{ md: '15px' }}
        >
          <Text as="h2" textStyle="h4.reg" color="blue.textHeader">
            Nutzerverwaltung:beta
          </Text>

          <VStack align="left" bg="bg.light" w="full" spacing="20px" borderRadius={{ md: '15px' }}>
            <Filters
              selectedSchool={selectedSchool}
              selectedVoucher={selectedVoucher}
              allSchools={allSchools}
              schoolRolesWithVoucher={schoolRolesWithVoucher}
              isOnlyOneSchool={isOnlyOneSchool}
              onSchoolChange={handleSchoolChange}
              onVoucherChange={handleVoucherChange}
            />

            <Table columns={usersColumns} data={mappedUsers || []} isDataLoading={isLoading} />

            {usersData?.totalPages && usersData?.totalPages > 1 && (
              <Pagination
                totalPages={usersData.totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
          </VStack>
        </VStack>
      </Box>
    </PageContext>
  );
};
