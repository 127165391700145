import { Box, Button, Flex, ModalBody, Text, Link as ExternalLink } from '@chakra-ui/react';
import { ReactElement, useEffect, useState } from 'react';
import { ModalSubscribeVariantProps, SharedModalProps } from 'types/Modal';
import { ExternalRoutes } from 'types/Routes';
import { PaymentInterval } from '@novaheal/types';

import { css } from '@emotion/react';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import { Breakpoints } from 'types/breakpoints';
import { useCreateSubscription } from 'hooks/api/backend/subscription/useCreateSubscription';

import { SessionStorageKey, useSessionStorage } from 'hooks/useSessionStorage';
import { PromoCode } from 'types/PromoCode';
import { TickCircleIcon } from 'assets/icons/TickCircleIcon';
import { GiftIcon } from 'assets/icons/Icons';
import { useUser } from 'utils/auth';
import { useReferralDetails } from 'hooks/api/backend/user/useReferral';
import { CustomModal } from '../../CustomModal';
import { SubscriptionBenefits } from './SubscriptionBenefits';
import { SubscriptionButtons } from './SubscriptionButtons';
import { SubscriptionSlider } from './SubscriptionSlider';
import { useSubscriptionPrices } from '../../../../../hooks/api/backend/subscription/useSubscriptionPrices';
import { useNovaToast } from '../../../../../hooks/utils/useNovaToast';
import { ToastVariant } from '../../../../../types/Toast';

type Props = SharedModalProps & ModalSubscribeVariantProps;

export const SubscribeModal = ({ handleClose, isShown }: Props): ReactElement | null => {
  const [activeButton, setActiveButton] = useState<PaymentInterval>(PaymentInterval.YEARLY);
  const { isPending: isLoading, mutateAsync: mutateAsyncSubscription } =
    useCreateSubscription(activeButton);
  const toast = useNovaToast();
  const promoCode = useSessionStorage<PromoCode>(SessionStorageKey.PROMO_CODE).getValue();
  const isCouponExpired = promoCode?.expirationDate
    ? new Date(promoCode.expirationDate) < new Date()
    : false;
  const [shouldShowCouponBanner, setShouldShowCouponBanner] = useState(
    !!promoCode?.id && !isCouponExpired && !promoCode?.package
  );
  const { data: user } = useUser();

  const { data: referralInfos } = useReferralDetails(!!user?.referralCode);

  const { data: subscriptionPrices, isError: subscriptionPricesError } = useSubscriptionPrices();

  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);
  const subscribeWidth = isDesktop ? '60%' : '100%';

  useEffect(() => {
    if (subscriptionPricesError) {
      toast.show({
        variant: ToastVariant.ERROR,
      });
    }
  }, [subscriptionPricesError, toast]);

  useEffect(() => {
    if (promoCode) {
      // TODO: think how to implement a redemption limit without asking stripe for it (without info about redemption count)
      // const redemptionLimitReached = promoCode.maxRedemptions;
      // if (redemptionLimitReached) {
      //   toast.show({
      //     ...toastContent.subscription.couponValidations.couponRedemptionLimitReached,
      //   });
      // }
    }
  }, [promoCode, toast]);

  const handleSubscribe = async (): Promise<void> => {
    const activeButtonPackage = activeButton === PaymentInterval.YEARLY ? 'yearly' : 'monthly';
    const shouldAddPromoCode =
      (!!promoCode?.id && !promoCode?.package) ||
      (!!promoCode?.package && activeButtonPackage === promoCode?.package);
    const checkoutLink = await mutateAsyncSubscription(
      shouldAddPromoCode ? promoCode?.id : undefined
    );

    if (checkoutLink) window.location.replace(checkoutLink);
  };

  return (
    <CustomModal
      isOpen={isShown}
      onClose={handleClose}
      isCentered
      hideHeader={isDesktop}
      isFullscreen={!isDesktop}
      maxWidth="850px"
      floatingCross={isDesktop}
    >
      <ModalBody
        p="0"
        css={css`
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
        `}
      >
        <Flex flexDirection={{ base: 'column-reverse', md: 'row' }} width={subscribeWidth}>
          {!isDesktop && (
            <Box
              position="sticky"
              bottom="0"
              display="grid"
              placeItems="center"
              width="100%"
              background="linear-gradient(180deg, rgba(253, 252, 254, 0) 0%, #FDFCFE 15.1%)"
              py="30px"
              zIndex="1"
            >
              <Button
                variant="primary"
                sx={{
                  '@supports (-webkit-touch-callout: none)': {
                    marginBottom: '20px',
                  },
                }}
                width="220px"
                onClick={handleSubscribe}
                isLoading={isLoading}
              >
                Abonnieren
              </Button>
            </Box>
          )}
          <Flex
            width="100%"
            bg={{ base: 'bg.light', md: 'bg.darken' }}
            gap="30px"
            p={{ base: '30px 0px 0px', md: '45px 0px 30px' }}
            flexDirection="column"
          >
            <Box px="25px">
              <SubscriptionButtons
                activeButton={activeButton}
                onClick={setActiveButton}
                onSubscribe={handleSubscribe}
                isLoading={isLoading}
                subscriptionPrices={subscriptionPrices || []}
                promoCode={!isCouponExpired ? promoCode : undefined}
                referralInfos={referralInfos}
              />
            </Box>
            {shouldShowCouponBanner && (
              <Box
                background="yellow"
                p="10px 20px"
                backgroundColor="#FEF7E0"
                display="flex"
                flexDir="row"
                gap="5px"
                alignItems="center"
              >
                <GiftIcon
                  color="grey.secondary"
                  height="20px"
                  width="20px"
                  transform="translateY(-1px)"
                />
                <Text textStyle="body.14.reg">Dein Promo-Code ist aktiv</Text>
                <TickCircleIcon color="green.primary" height="16px" width="16px" />
              </Box>
            )}
            <Box px="25px">
              <SubscriptionBenefits />
            </Box>
            <Box px="25px">
              <Text textStyle="body.12.reg" textAlign="center">
                Mit deiner Bestellung erklärst du dich mit unseren{' '}
                <ExternalLink href={ExternalRoutes.TERMS} color="blue.highlighted" target="_blank">
                  Nutzungsbedingungen und Widerrufsbestimmungen
                </ExternalLink>{' '}
                einverstanden
              </Text>
            </Box>
          </Flex>
          {!isDesktop && (
            <Box>
              <SubscriptionSlider />
            </Box>
          )}
        </Flex>
      </ModalBody>
      {isDesktop && (
        <Box
          position="absolute"
          height="500px"
          width="40%"
          left="100%"
          top="50%"
          transform="translateY(-50%) translateX(-100%)"
        >
          <SubscriptionSlider />
        </Box>
      )}
    </CustomModal>
  );
};
