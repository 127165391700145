import { Button, ModalBody, Text, Flex } from '@chakra-ui/react';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import React, { ReactElement } from 'react';
import { Breakpoints } from 'types/breakpoints';
import { ModalShareVariantProps, SharedModalProps } from 'types/Modal';
import { Form, InputField } from 'components/common/Form';
import { EmailIcon } from '@chakra-ui/icons';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { toastContent } from 'config/toast';
import { ToastVariant } from 'types/Toast';
import { InviteResponse, useInviteUser } from 'hooks/api/backend/user/useReferral';
import { CustomModal } from '../CustomModal';

type Props = SharedModalProps & ModalShareVariantProps;
type MailInput = {
  email: string;
};

export const ShareModal = ({ handleClose, isShown }: Props): ReactElement => {
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);
  const { mutateAsync: inviteUser, isPending } = useInviteUser();
  const { show: showToast } = useNovaToast();

  const handleSubmit = async (values: MailInput): Promise<void> => {
    const inviteStatus = await inviteUser(values.email);
    switch (inviteStatus) {
      case InviteResponse.INVITE_SUCCESS:
        showToast(toastContent.referral.inviteSuccess);
        handleClose();
        break;
      case InviteResponse.INVITE_ERROR:
        showToast(toastContent.referral.inviteError);
        break;

      default:
        showToast({ ...toastContent.error.default, variant: ToastVariant.ERROR });
        break;
    }
  };

  return (
    <CustomModal
      isOpen={isShown}
      onClose={handleClose}
      isCentered
      maxWidth={{ base: '300px', md: '600px' }}
      hideHeader={!isDesktop}
    >
      <ModalBody p={{ base: '0', md: '0 40px 30px' }} display="flex" flexDirection="column">
        <Flex
          p={{ base: '20px', md: '0' }}
          textAlign={{ base: 'center', md: 'left' }}
          mb={{ md: '30px' }}
          flexDir="column"
          gap="15px"
        >
          <Text textStyle="h1" textColor="blue.textHeader">
            Gemeinsam zum Erfolg!
          </Text>
          <Text>
            Lade Freunde ein und helft euch gegenseitig - mit <b>20 %</b> Rabatt auf Novaheal für
            deine Freunde.
          </Text>
          <Form onSubmit={(values) => handleSubmit(values)}>
            {({ register }) => {
              return (
                <Flex flexDirection="column" gap="40px" alignItems="center" pt="5px" width="100%">
                  <InputField
                    type="email"
                    registration={register('email')}
                    leftElement={<EmailIcon color="grey.40" />}
                    placeholder="Mail"
                    _focus={{ bg: 'bg.input', borderColor: 'green.primary' }}
                  />
                  <Button
                    type="submit"
                    variant="primary"
                    w={{ base: 'full', md: '220px' }}
                    isLoading={isPending}
                  >
                    Freund:in einladen
                  </Button>
                </Flex>
              );
            }}
          </Form>
        </Flex>
      </ModalBody>
    </CustomModal>
  );
};
