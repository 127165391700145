/* eslint-disable jsx-a11y/media-has-caption */
import React, { ReactElement } from 'react';
import { Box, useDisclosure, Image } from '@chakra-ui/react';
import PlaceholderImage from '../../../assets/common/placeholder.png';
import { CustomPlayButton } from './CustomPlayButton';
import { VideoModal } from './VideoModal';

type Props = {
  videoUrl: string;
  videoDescription?: string;
  isEmbedded?: boolean;
  thumbnailUrl?: string;
  onVideoEnd?: () => void;
  onVideoAbort?: () => void;
};

export const VideoBox = ({
  videoUrl,
  videoDescription = '',
  isEmbedded = false,
  thumbnailUrl = PlaceholderImage,
  onVideoEnd,
  onVideoAbort,
}: Props): ReactElement => {
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

  const getThumbnailBox = (): ReactElement | null => {
    if (!videoUrl) return null;

    if (isEmbedded)
      return (
        <Image
          src={thumbnailUrl || PlaceholderImage}
          objectFit="cover"
          height="100%"
          width="100%"
          alt={videoDescription}
        />
      );

    return (
      <Box as="video" width="100%" height="100%" objectFit="cover">
        <source src={videoUrl} type="video/mp4" />
      </Box>
    );
  };

  return (
    <>
      <Box height="100%" position="relative" cursor="pointer" onClick={onModalOpen}>
        <Box
          height="100%"
          width="100%"
          position="absolute"
          top="0"
          left="0"
          display="grid"
          placeItems="center"
        >
          <CustomPlayButton />
        </Box>
        {getThumbnailBox()}
      </Box>
      <VideoModal
        videoUrl={videoUrl}
        isEmbedded={isEmbedded}
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        videoDescription={videoDescription}
        onVideoEnd={onVideoEnd}
        onVideoAbort={onVideoAbort}
      />
    </>
  );
};
