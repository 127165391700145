import { Role, Voucher } from '@novaheal/types';
import { useQuery, useMutation, UseQueryResult, UseMutationResult } from '@tanstack/react-query';
import { axios } from 'utils/axios';

export type DashboardData = {
  allSchools: School[];
  schoolRolesWithVoucher: SchoolRolesWithVoucher;
};

export type School = {
  id: string;
  name: string;
};

export type SchoolRolesWithVoucher = {
  [key: string]: {
    b2bSchoolId: string;
    b2bSchoolName: string;
    createdAt: string;
    roles: Role;
    updatedAt: string;
    userId: string;
    vouchers: SchoolVoucher[];
  };
};

export type SchoolVoucher = {
  id: string;
  shortCode: string;
};

export type SchoolUsers = {
  data: SchoolUser[];
  totalPages: number;
};

export type SchoolUser = {
  b2bSchoolId: string;
  educationStatus: string;
  email: string;
  firstName: string;
  id: string;
  subscriptionStatus: string; // not needed
  subscriptionUpdatedAt: string; // not needed
  voucherId: string;
  voucherShortCode: string;
};

const getUsers = async (
  b2bSchoolId: string,
  voucherId?: string,
  page?: number
): Promise<SchoolUsers | null> => {
  if (!b2bSchoolId) return null;
  if (voucherId && !b2bSchoolId) return null;
  const { data: userManagementContent } = await axios.get<SchoolUsers>('/management/user', {
    params: {
      b2bSchoolId,
      voucherId,
      page,
    },
  });
  return userManagementContent;
};

const kickUserFromOrganization = async (
  userId: string,
  voucherId: string,
  b2bSchoolId: string
): Promise<void> => {
  const { data: response } = await axios.post<void>('/management/user', {
    userId,
    voucherId,
    b2bSchoolId,
  });
  return response;
};

const getVouchers = async (b2bSchoolId: string): Promise<Voucher[]> => {
  const { data: vouchers } = await axios.get<Voucher[]>('/management/voucher', {
    params: {
      b2bSchoolId,
    },
  });
  return vouchers;
};

export const useSchoolVouchers = (
  b2bSchoolId: string
): UseQueryResult<Voucher[] | null, unknown> => {
  return useQuery({
    queryKey: ['vouchers', b2bSchoolId],
    queryFn: () => getVouchers(b2bSchoolId),
    enabled: !!b2bSchoolId,
  });
};

const getDashboardData = async (): Promise<DashboardData> => {
  const { data: dashboardData } = await axios.get<DashboardData>('/management/dashboard', {});
  return dashboardData;
};

export const useUserManagement = (): UseQueryResult<DashboardData | null, unknown> => {
  return useQuery({
    queryKey: ['user-management-get-vouchers'],
    queryFn: () => getDashboardData(),
  });
};

export const useUsers = (
  b2bSchoolId: string,
  voucherId?: string,
  page?: number
): UseQueryResult<SchoolUsers | null, unknown> => {
  return useQuery({
    queryKey: ['users', b2bSchoolId, voucherId, page],
    queryFn: () => getUsers(b2bSchoolId, voucherId, page),
    enabled: !!b2bSchoolId,
  });
};

export const useKickUser = (
  refetch: () => void
): UseMutationResult<
  void,
  unknown,
  { userId: string; voucherId: string; b2bSchoolId: string },
  unknown
> => {
  return useMutation({
    mutationFn: ({
      userId,
      voucherId,
      b2bSchoolId,
    }: {
      userId: string;
      voucherId: string;
      b2bSchoolId: string;
    }) => kickUserFromOrganization(userId, voucherId, b2bSchoolId),
    onSuccess: () => {
      refetch();
    },
  });
};
