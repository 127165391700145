import { SubscriptionStatus } from '@novaheal/types';
import { PageSpinner } from 'components/atoms/PageSpinner';
import { useModalService } from 'hooks/useModalService';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import React, { useEffect } from 'react';
import { Breakpoints } from 'types/breakpoints';
import { ModalTarget, ModalVariant } from 'types/Modal';
import { useUser } from 'utils/auth';
import { isSubscriptionStatus } from 'utils/isSubscriptionStatus';

/**
 * Higher-Order Component (HOC) to enforce subscription checks for a wrapped React component.
 *
 * This HOC ensures that users with an appropriate subscription status can access the content.
 * If the user is on a free plan, it:
 * - Logs a breadcrumb in Sentry for tracking purposes.
 * - Displays a paywall modal after a 500ms delay.
 * - Shows a loading spinner (`PageSpinner`) while the modal is displayed.
 *
 * @template P - The props type of the wrapped component.
 *
 * @param {React.ComponentType<P>} WrappedComponent - The React component to be wrapped.
 *
 * @returns {React.FC<P>} A new component that enforces the subscription check logic and renders the
 *                        `WrappedComponent` when the user has the required subscription status. If Guard is active it will render a Loading Spinner underneath the modal.
 *
 * @example
 * const MyComponent = (props) => <div>Welcome to the premium content!</div>;
 *
 * const GuardedComponent = withSubscriptionGuard(MyComponent);
 *
 * export const App = () => <GuardedComponent someProp="value" />;
 */
export const withSubscriptionGuard = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P> => {
  return (props: P) => {
    const { show: showModal, activeModal } = useModalService();
    const { data: user } = useUser();
    const isDesktop = useBreakpoint(Breakpoints.DESKTOP);
    const isFree = isSubscriptionStatus(user, SubscriptionStatus.FREE);

    useEffect(() => {
      if (isFree) {
        const shouldShowPaywall = activeModal !== ModalVariant.SUBSCRIBE;
        if (shouldShowPaywall) {
          showModal(ModalVariant.PAYWALL, {
            target: isDesktop ? ModalTarget.PAGE_DESKTOP : ModalTarget.PAGE_MOBILE,
            onUpgradeClick: () => showModal(ModalVariant.SUBSCRIBE),
          });
        }
      }
    }, [isFree, showModal, activeModal, isDesktop]);

    if (isFree) {
      return <PageSpinner />;
    }

    return <WrappedComponent {...props} />;
  };
};
