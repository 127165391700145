import React, { ReactElement } from 'react';
import { Text, Button, Flex } from '@chakra-ui/react';

type Props = {
  totalPages: number;
  currentPage: number;
  onPageChange: (newPage: number) => void;
};

export const Pagination = ({ totalPages, currentPage, onPageChange }: Props): ReactElement => {
  return (
    <Flex justifyContent="center" alignItems="center" width="100%" gap={4}>
      <Button onClick={() => onPageChange(currentPage - 1)} isDisabled={currentPage === 1}>
        Vorherige
      </Button>
      <Text>
        Seite {currentPage} von {totalPages}
      </Text>
      <Button onClick={() => onPageChange(currentPage + 1)} isDisabled={currentPage === totalPages}>
        Nächste
      </Button>
    </Flex>
  );
};
