import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { axios } from 'utils/axios';

export enum ReferralResponse {
  REDEEM_SUCCESS = 'redeemSuccess',
  WRONG_CODE = 'wrongCode',
  EXPIRED_CODE = 'expiredCode',
}

export enum InviteResponse {
  INVITE_SUCCESS = 'inviteSuccess',
  INVITE_ERROR = 'inviteError',
}

export type ReferralInfoDto = {
  id: string;
  shortCode: string;
  discount: {
    value: number;
    unit: 'percent' | 'currency';
  };
  eligibleUntil: string;
};

const redeemReferral = async (shortCode: string): Promise<ReferralResponse> => {
  try {
    await axios.post<void>('/referral/redeem', { shortCode });

    return ReferralResponse.REDEEM_SUCCESS;
  } catch (error) {
    const status = (error as AxiosError).response?.status;

    if (status === 404) {
      return ReferralResponse.WRONG_CODE;
    }
    if (status === 410) {
      return ReferralResponse.EXPIRED_CODE;
    }

    throw error;
  }
};

export const useReferral = (): UseMutationResult<ReferralResponse, unknown, string, unknown> => {
  return useMutation({
    mutationKey: ['redeem-Referral'],
    mutationFn: redeemReferral,
  });
};

const getReferralDetails = async (): Promise<ReferralInfoDto> => {
  const { data: referralInfo } = await axios.get<ReferralInfoDto>('/referral');
  return referralInfo;
};

export const useReferralDetails = (isEnabled: boolean): UseQueryResult<ReferralInfoDto> => {
  return useQuery({
    queryKey: ['referral-details'],
    queryFn: getReferralDetails,
    enabled: isEnabled,
  });
};

const inviteUser = async (email: string): Promise<InviteResponse> => {
  try {
    await axios.post('/referral/invite', {
      email,
    });

    return InviteResponse.INVITE_SUCCESS;
  } catch (error) {
    const status = (error as AxiosError).response?.status;

    if (status === 400) {
      return InviteResponse.INVITE_ERROR;
    }
    throw error;
  }
};

export const useInviteUser = (): UseMutationResult<InviteResponse, unknown, string, unknown> => {
  return useMutation({
    mutationFn: inviteUser,
  });
};
