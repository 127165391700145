import { Text, Button, Flex, Box } from '@chakra-ui/react';
import { ReactElement, useState } from 'react';
import Joi from 'joi';
import { Form, InputField } from 'components/common/Form';
import { FieldError } from 'react-hook-form';
import { caughtErrorGlobally } from 'utils/axios';
import { AxiosError } from 'axios';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { ToastVariant } from 'types/Toast';
import { useWelcomePostStep } from 'hooks/api/backend/welcome/useWelcomeStep';
import { toastContent } from 'config/toast';
import { ErrorStatusType } from 'types/StatusTypes';
import { WelcomeScreenOptions } from './Welcome';

type DobValues = {
  birthday: string;
};

const schema = Joi.object({
  birthday: Joi.date().required().messages({
    'any.required': 'Bitte gib ein gültiges Datum ein',
    'date.base': 'Bitte gib ein gültiges Datum ein',
  }),
});

export const Dob = (props: WelcomeScreenOptions): ReactElement => {
  const { onContinue, stepData } = props;
  const toast = useNovaToast();
  const [isLoading, setLoading] = useState(false);
  const { mutateAsync: updateWelcomeStep } = useWelcomePostStep();

  const handleSubmit = async (values: DobValues): Promise<void> => {
    const birthdayAsIsoString = new Date(values.birthday).toISOString();
    setLoading(true);
    try {
      const onboardingData = await updateWelcomeStep({
        stepIdentifier: stepData.currentStepIdentifier,
        totalSteps: stepData.totalSteps,
        step: stepData.currentStep,
        data: {
          birthdate: birthdayAsIsoString,
        },
      });
      onContinue(onboardingData);
    } catch (error) {
      if (caughtErrorGlobally(error as AxiosError)) {
        toast.show(toastContent.error[ErrorStatusType.SERVER_ERROR]);
        return;
      }
      toast.show({
        variant: ToastVariant.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex width="100%" direction="column" gap="0px">
      <Text as="h1" textStyle="h2" color="blue.textHeader" mb="25px">
        Geburtsdatum
      </Text>
      <Text textStyle="body.16.reg" color="grey.secondary" mb="40px">
        Bitte gib dein Geburtsdatum ein.
      </Text>

      <Form<DobValues, typeof schema> onSubmit={handleSubmit} schema={schema} autoComplete="off">
        {({ register, formState, setValue, getValues, watch, resetField }) => {
          return (
            <>
              <Flex direction="row" gap="20px" alignItems="center" justifyContent="space-between">
                <Text textStyle="body.16.reg" color="grey.secondary" mb="10px">
                  Geburtsdatum:
                </Text>

                <Box>
                  <InputField
                    // label="Geburtsdatum"
                    type="date"
                    registration={register('birthday')}
                    // {...register('birthday')}
                    error={formState.errors.birthday as FieldError}
                    onChange={(e) => {
                      setValue('birthday', new Date(e.target.value).toISOString());
                    }}
                    maxW="350px"
                  />
                </Box>
              </Flex>

              <Button
                translate="no"
                variant="primary"
                type="submit"
                isLoading={isLoading}
                loadingText="Speichern"
                mt="40px"
                w="full"
                py="10px"
              >
                Weiter
              </Button>
            </>
          );
        }}
      </Form>
    </Flex>
  );
};
