import { Flex, Select } from '@chakra-ui/react';
import { ReactElement } from 'react';

type FiltersProps = {
  selectedSchool: string;
  selectedVoucher: string;
  allSchools: { id: string; name: string }[];
  schoolRolesWithVoucher: Record<string, { vouchers: { id: string; shortCode: string }[] }>;
  isOnlyOneSchool: boolean;
  onSchoolChange: (schoolId: string) => void;
  onVoucherChange: (voucherId: string) => void;
};

export const Filters = ({
  selectedSchool,
  selectedVoucher,
  allSchools,
  schoolRolesWithVoucher,
  isOnlyOneSchool,
  onSchoolChange,
  onVoucherChange,
}: FiltersProps): ReactElement => {
  return (
    <Flex gap={4} width="100%">
      <Select
        placeholder="Schule auswählen"
        value={selectedSchool}
        onChange={(e) => onSchoolChange(e.target.value)}
        isDisabled={isOnlyOneSchool}
        flex={1}
      >
        {allSchools.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Select>

      <Select
        placeholder="Organisationslizenz auswählen"
        value={selectedVoucher}
        onChange={(e) => onVoucherChange(e.target.value)}
        isDisabled={!selectedSchool}
        flex={1}
      >
        {schoolRolesWithVoucher[selectedSchool]?.vouchers?.map(({ id, shortCode }) => (
          <option key={id} value={id}>
            {shortCode}
          </option>
        ))}
      </Select>
    </Flex>
  );
};
