// TODO: (Later iteration) autofill & disable school fields if voucher got entered

import { Text, Button, VStack, Flex, Center, HStack, Link } from '@chakra-ui/react';
import { ChangeEvent, ReactElement, useState } from 'react';
import Joi from 'joi';
import { AutocompleteField, Form, InputField, SelectField } from 'components/common/Form';
import { useSchoolApi } from 'hooks/api/backend/useSchoolsApi';
import { FieldError } from 'react-hook-form';

// assets
import { EducationStatus, School } from '@novaheal/types';
import { CloseIcon } from '@chakra-ui/icons';
import { caughtErrorGlobally } from 'utils/axios';
import { AxiosError } from 'axios';
import { AcademicHatIcon, AcademyIcon, CalendarIcon } from 'assets/icons/Icons';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { ToastVariant } from 'types/Toast';
import { errorMessage } from 'components/common/Form/ErrorMessages';
import { useFirebase } from 'hooks/useFirebase';
import { useModalService } from 'hooks/useModalService';
import { ModalVariant } from 'types/Modal';
import { useWelcomePostStep } from 'hooks/api/backend/welcome/useWelcomeStep';
import { EduPrefillData, UpdateEducationDataDto } from 'types/WelcomeScreen';
import { ErrorStatusType } from 'types/StatusTypes';
import { toastContent } from 'config/toast';
import { WelcomeScreenOptions } from './Welcome';

type OrganizationInput = {
  name: string;
  id: string | null;
  educationStatus: EducationStatus;
  examinationMonth?: number;
  examinationYear?: number;
};

type Props = WelcomeScreenOptions & {
  prefilledData?: {
    educationStatus: EducationStatus | null;
    school?: {
      isDisabled: boolean;
      value?: string;
      id?: string;
    };
    userExamination: {
      id: string;
      month: number;
      year: number;
      userId: string;
    } | null;
  };
};

const schema = Joi.object({
  name: Joi.string().required().messages({
    'string.empty': errorMessage.empty,
  }),
  id: Joi.string().allow(null).min(2),
  educationStatus: Joi.string().required().messages({
    'string.empty': errorMessage.empty,
  }),
  examinationMonth: Joi.number()
    .allow(null)
    .when('educationStatus', {
      is: Joi.valid('FIRST', 'SECOND', 'LAST'),
      then: Joi.number().messages({
        'string.empty': errorMessage.empty,
      }),
      otherwise: Joi.optional().allow(''),
    }),
  examinationYear: Joi.when('educationStatus', {
    is: Joi.valid('FIRST', 'SECOND', 'LAST'),
    then: Joi.number().required().min(2000).max(2999).messages({
      'string.empty': errorMessage.empty,
      'number.min': errorMessage.year,
      'number.max': errorMessage.year,
      'number.base': errorMessage.year,
      'number.empty': errorMessage.year,
    }),
    otherwise: Joi.optional().allow(''),
  }),
});

const EDUCATION_STATUS_OPTIONS = [
  {
    label: '1. Lehrdrittel',
    value: 'FIRST',
  },
  {
    label: '2. Lehrdrittel',
    value: 'SECOND',
  },
  {
    label: '3. Lehrdrittel',
    value: 'LAST',
  },
  {
    label: 'Studierende:r',
    value: 'STUDENT',
  },
  {
    label: 'Examiniert',
    value: 'DONE',
  },
  {
    label: 'Praxisanleitung',
    value: 'GUIDE',
  },
  {
    label: 'Lehrkraft',
    value: 'TEACH',
  },
  {
    label: 'Pflegefachassistenz',
    value: 'NURSING_ASSISTANT',
  },
  {
    label: 'Andere',
    value: 'OTHER',
  },
];

const MONTH_OPTIONS = [
  {
    label: 'Januar',
    value: 1,
  },
  {
    label: 'Februar',
    value: 2,
  },
  {
    label: 'März',
    value: 3,
  },
  {
    label: 'April',
    value: 4,
  },
  {
    label: 'Mai',
    value: 5,
  },
  {
    label: 'Juni',
    value: 6,
  },
  {
    label: 'Juli',
    value: 7,
  },
  {
    label: 'August',
    value: 8,
  },
  {
    label: 'September',
    value: 9,
  },
  {
    label: 'Oktober',
    value: 10,
  },
  {
    label: 'November',
    value: 11,
  },
  {
    label: 'Dezember',
    value: 12,
  },
];

export const EducationInfo = (options: Props): ReactElement => {
  const { onContinue, stepData } = options;
  const prefilledData = stepData.currentData?.prefillData as EduPrefillData;
  const { getAutoCompleteItems } = useSchoolApi();
  const toast = useNovaToast();
  const [isLoading, setLoading] = useState(false);
  const { logFirebaseUserData } = useFirebase();
  const modalService = useModalService();
  const { mutateAsync: updateWelcomeStep } = useWelcomePostStep();
  const shouldShowExaminationFields = (selectedEduOption: string): boolean => {
    const fieldsToShowExamination = ['FIRST', 'SECOND', 'LAST'];
    return fieldsToShowExamination.includes(selectedEduOption);
  };
  const [voucherSchoolData, setVoucherSchoolData] = useState<School | null>(null);

  const onVoucherActivation = (schoolData?: School): void => {
    if (schoolData)
      setVoucherSchoolData({
        name: schoolData.name,
        id: schoolData.id,
      });
  };

  const handleVoucherClick = (): void => {
    modalService.show(ModalVariant.VOUCHER, {
      onSuccess: onVoucherActivation,
    });
  };

  const handleSubmit = async (values: OrganizationInput): Promise<void> => {
    setLoading(true);
    try {
      const onboardingData = await updateWelcomeStep({
        stepIdentifier: stepData.currentStepIdentifier,
        totalSteps: stepData.totalSteps,
        step: stepData.currentStep,
        data: {
          b2cSchoolData: {
            id: values.id,
            name: values.name.trim(),
          },
          educationStatus: values.educationStatus,
          // Only send examination fields if examinationData should show
          ...(shouldShowExaminationFields(values.educationStatus) && {
            examination: {
              month: values.examinationMonth,
              year: values.examinationYear,
            },
          }),
        } as UpdateEducationDataDto,
      });
      logFirebaseUserData({
        userSchool: values.name,
        userEducationStatus: values.educationStatus,
      });
      onContinue(onboardingData);
    } catch (error) {
      if (caughtErrorGlobally(error as AxiosError)) {
        toast.show(toastContent.error[ErrorStatusType.SERVER_ERROR]);
        return;
      }
      toast.show({
        variant: ToastVariant.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex width="100%" direction="column" gap="0px" alignItems="center">
      <Text as="h1" textStyle="h2" color="blue.textHeader" mb="25px">
        Gib uns noch ein paar Infos, damit wir dein Lernerlebnis optimieren können.
      </Text>
      <Form<OrganizationInput, typeof schema>
        onSubmit={handleSubmit}
        schema={schema}
        autoComplete="off"
      >
        {({ register, formState, setValue, getValues, watch, resetField }) => {
          const { isValid } = formState;

          return (
            <>
              <Flex direction="column" gap="20px">
                <AutocompleteField
                  getAutocompleteItems={getAutoCompleteItems}
                  initalName={prefilledData?.school?.value ?? voucherSchoolData?.name ?? undefined}
                  initalId={prefilledData?.school?.id ?? voucherSchoolData?.id ?? undefined}
                  isDisabled={prefilledData?.school?.isDisabled || !!voucherSchoolData?.id}
                  registrationName="name"
                  error={formState.errors.name as FieldError}
                  idName="id"
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  placeholder="Pflegeschule o. Institution"
                  leftElement={<AcademyIcon color={watch('name') ? 'green.primary' : 'grey.40'} />}
                  zIndex={1}
                  rightElement={
                    !(prefilledData?.school?.isDisabled || !!voucherSchoolData?.id) &&
                    watch('name') && (
                      <Button
                        ml={4}
                        bg="none"
                        _hover={{ backgroundColor: 'none' }}
                        _active={{ backgroundColor: 'none' }}
                        _focus={{ outline: 'none' }}
                        onClick={() => resetField('name')}
                      >
                        <Center bg="grey.40" rounded="full" boxSize="16px">
                          <CloseIcon color="bg.light" mt="3px" w="10px" m={0} />
                        </Center>
                      </Button>
                    )
                  }
                />
                <SelectField
                  options={EDUCATION_STATUS_OPTIONS}
                  placeholder="Ausbildungsstatus"
                  error={formState.errors.educationStatus as FieldError}
                  registration={register('educationStatus')}
                  leftElement={
                    <AcademicHatIcon
                      color={watch('educationStatus') ? 'green.primary' : 'grey.40'}
                    />
                  }
                  defaultValue={prefilledData?.educationStatus ?? undefined}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    setValue('educationStatus', e.target.value, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    })
                  }
                />

                {shouldShowExaminationFields(watch('educationStatus')) && (
                  <VStack width="100%" alignItems="flex-start" gap="15px">
                    <Text textStyle="body.16.reg" textAlign="left">
                      Voraussichtlicher Examenstermin
                    </Text>
                    <HStack
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      width="100%"
                      gap="20px"
                    >
                      <SelectField
                        options={MONTH_OPTIONS}
                        placeholder="Prüfungsmonat"
                        error={formState.errors.examinationMonth as FieldError}
                        registration={register('examinationMonth')}
                        defaultValue={prefilledData?.userExamination?.month}
                        leftElement={
                          <CalendarIcon
                            color={watch('examinationMonth') ? 'green.primary' : 'grey.40'}
                          />
                        }
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setValue('examinationMonth', e.target.value, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                          })
                        }
                      />
                      <InputField
                        placeholder="Prüfungsjahr"
                        error={formState.errors.examinationYear as FieldError}
                        registration={register('examinationYear')}
                        defaultValue={prefilledData?.userExamination?.year}
                        leftElement={
                          <CalendarIcon
                            color={watch('examinationYear') ? 'green.primary' : 'grey.40'}
                          />
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setValue('examinationYear', e.target.value, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                          })
                        }
                      />
                    </HStack>
                  </VStack>
                )}
                <Text textStyle="body.14.reg">
                  Du hast einen Code für Novaheal erhalten? Hier kannst du{' '}
                  <Link color="blue.highlighted" onClick={handleVoucherClick} display="inline">
                    deinen Code eingeben
                  </Link>
                </Text>
              </Flex>

              <Button
                translate="no"
                variant="primary"
                type="submit"
                isLoading={isLoading}
                disabled={!isValid}
                isDisabled={!isValid}
                loadingText="Speichern"
                mt="40px"
                w="full"
                py="10px"
              >
                Weiter
              </Button>
            </>
          );
        }}
      </Form>
    </Flex>
  );
};
