import { ReactElement, useEffect, useRef } from 'react';
import { Box, Flex, useDisclosure, useTheme } from '@chakra-ui/react';
import { Search } from '@novaheal/types';
import { useSearchHistoryEvents, useAutocomplete } from 'hooks/api/backend/search/search';
import { Hit } from 'instantsearch.js';
import { IconType } from 'types/iconType';
import { useStore } from 'store/useStore';
import { useNavigate } from 'react-router-dom';
import { ProtectedRoutes } from 'types/Routes';
import { Z_INDEX } from 'styles/zIndex';
import { SearchInput } from './SearchInput';
import { SearchDropdownList } from './SearchDropdownList/SearchDropdownList';
import { useBreakpoint } from '../../hooks/utils/layout/useBreakpoint';
import { Breakpoints } from '../../types/breakpoints';

export const SearchBar = (): ReactElement => {
  const { setDropdownOpen } = useStore();
  const { data: searchHistory } = useSearchHistoryEvents();
  const { indices, currentRefinement } = useAutocomplete();
  const { isOpen: dropdownIsOpen, onClose: hideDropdown, onOpen: showDropdown } = useDisclosure();
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const showCancelButton = !isDesktop && (dropdownIsOpen || currentRefinement !== '');
  const hasHistoryResults = !!searchHistory && searchHistory.length > 0;
  const showHistory = hasHistoryResults && currentRefinement.length <= 1;

  const containerBg = isDesktop ? undefined : 'green.primary';
  const containerMaxW = theme.container.width.md;
  const containerPaddingX = isDesktop ? theme.container.paddingX.md : '0';
  const containerMargin = isDesktop ? '0 auto' : '0 !important';

  // Update dropdown state in store to tell the mobile layout to hide the topbar
  useEffect(() => {
    setDropdownOpen(dropdownIsOpen);
  }, [dropdownIsOpen, setDropdownOpen]);

  // Hide dropdown listeners
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        inputRef.current?.blur();
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  const handleFocus = (): void => {
    showDropdown();
  };

  const handleBlur = (): void => {
    hideDropdown();
  };

  const handleCancelClick = (): void => {
    inputRef.current?.blur();
  };

  const navigateToSearchPage = (): void => {
    navigate(ProtectedRoutes.SEARCH);
  };

  const handleSeeMoreClick = (): void => {
    inputRef.current?.blur();
    navigateToSearchPage();
  };

  const handleSubmit = (): void => {
    inputRef.current?.blur();
    navigateToSearchPage();
  };

  const searchRecommendations = indices[0].hits.map((hit: Hit) => ({
    text: hit.fields.title || hit.fields.name,
    id: hit.objectID,
    contentType: hit.contentType,
  }));

  const dropdownData = showHistory
    ? {
        items: searchHistory.map((item: Search) => ({
          text: item.searchTerm,
          id: item.id,
        })),
        variant: IconType.HISTORY,
      }
    : {
        items: searchRecommendations,
        variant: IconType.RECOMMEND,
      };

  const dropdownItemFn = async (id: string, text: string): Promise<void> => {
    if (inputRef.current) {
      // get and set the current value within react context
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      )?.set;
      nativeInputValueSetter?.call(inputRef.current, text);

      // fire a new event to trigger the change as if the value change was native
      const event = new Event('input', { bubbles: true });
      inputRef.current.dispatchEvent(event);
    }
    handleSubmit();
  };

  return (
    <Flex
      justifyContent="space-between"
      flexDirection="column"
      bg={containerBg}
      width="100%"
      maxW={containerMaxW}
      paddingX={containerPaddingX}
      zIndex={Z_INDEX.SEARCH_BAR}
      margin={containerMargin}
    >
      <Box p={3} w="100%">
        <Box width="100%" position="relative">
          <SearchInput
            ref={inputRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onCancelClick={handleCancelClick}
            showCancelButton={showCancelButton}
            onSubmit={handleSubmit}
          />
          <SearchDropdownList
            isOpen={dropdownIsOpen}
            listItems={dropdownData.items}
            variant={dropdownData.variant}
            onItemClick={dropdownItemFn}
            onSeeMoreClick={handleSeeMoreClick}
          />
        </Box>
      </Box>
    </Flex>
  );
};
