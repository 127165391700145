import { useEffect, useState } from 'react';
import { useNotifications } from './api/backend/useNotifications';
import { useNovaToast } from './utils/useNovaToast';

export const useNotificationService = (): void => {
  const [shownToastIds, setShownToastIds] = useState<string[]>([]);

  const { data: notifications, refetch } = useNotifications();
  const toast = useNovaToast();

  useEffect(() => {
    const filteredNotifications = notifications?.filter(
      (notification) => !shownToastIds.includes(notification.notificationId)
    );
    const interval = 1000;

    if (!!filteredNotifications && filteredNotifications?.length > 0) {
      filteredNotifications.forEach((notification, index) => {
        setTimeout(() => {
          toast.show({
            title: notification.title,
            description: notification.description,
            variant: notification.variant,
          });
        }, interval * index);
        setShownToastIds([...shownToastIds, ...filteredNotifications.map((n) => n.notificationId)]);
      });
    }
  }, [notifications, toast, shownToastIds]);

  useEffect(() => {
    const fetchInterval = setInterval(() => {
      refetch();
    }, 3600000); // 60 minutes in milliseconds

    return () => clearInterval(fetchInterval);
  }, [refetch]);
};
