export enum SessionStorageKey {
  PROMO_CODE = 'promoCode',
  DEFERRED_URL = 'deferredUrl',
  DID_USER_LOGOUT = 'hasLoggedOut',
  REF_ID = 'refId',
}

export const useSessionStorage = <T>(
  key: SessionStorageKey,
  initialValue?: T
): {
  getValue: () => T | undefined;
  setValue: (value: T) => void;
  deleteItem: () => void;
} => {
  const getValue = (): T | undefined => {
    if (typeof window === 'undefined') return initialValue;

    try {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch {
      return initialValue;
    }
  };

  const setValue = (value: T): void => {
    if (typeof window === 'undefined') return;

    sessionStorage.setItem(key, JSON.stringify(value));
  };

  const deleteItem = (): void => {
    if (typeof window === 'undefined') return;

    sessionStorage.removeItem(key);
  };

  return { getValue, setValue, deleteItem };
};
