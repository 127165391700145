import { Tabs, TabList, TabPanels, ResponsiveValue } from '@chakra-ui/react';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import { ReactElement } from 'react';
import { Z_INDEX } from 'styles/zIndex';
import { Breakpoints } from 'types/breakpoints';
import { TabHeader } from 'types/tab';
import { ResultsBar } from '../Search/ResultsBar';
import { CustomTab } from './CustomTab';

export enum TabBarVariant {
  ARTICLE = 'ARTICLE',
  SEARCH = 'SEARCH',
  FAVORITE = 'FAVORITE',
}

type TabBarProps = {
  tabs: TabHeader[];
  position?: ResponsiveValue<'fixed' | 'sticky' | 'relative' | 'absolute'>;
  top?: ResponsiveValue<number | string>;
  children?: React.ReactNode;
  variant?: TabBarVariant;
};

export const TabBar = ({
  tabs,
  position,
  top,
  children,
  variant = TabBarVariant.ARTICLE,
}: TabBarProps): ReactElement => {
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);
  const boxShadow =
    variant === TabBarVariant.SEARCH && !isDesktop ? '0px 0px 16px rgba(0, 0, 0, 0.1)' : undefined;
  const justifyContent = variant === TabBarVariant.SEARCH ? '' : 'space-between';
  const isFitted = variant !== TabBarVariant.SEARCH;
  const shouldShowResultsBar = variant === TabBarVariant.SEARCH && !isDesktop;

  return (
    <Tabs w="100%" isFitted={isFitted}>
      <TabList
        borderColor="bg.light"
        color="grey.50"
        justifyContent={justifyContent}
        bg="bg.light"
        h="50px"
        w="100%"
        px={{ base: '20px', md: '40px' }}
        position={position}
        top={top}
        boxShadow={boxShadow}
        zIndex={Z_INDEX.BASE}
      >
        {tabs.map((tab: TabHeader) => (
          <CustomTab {...tab} variant={variant} key={tab.tabName} />
        ))}
      </TabList>
      {shouldShowResultsBar && <ResultsBar />}
      <TabPanels>{children}</TabPanels>
    </Tabs>
  );
};
