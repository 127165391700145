// TODO: (Later iteration) autofill & disable school fields if voucher got entered

import { Text, Button, Flex, Center } from '@chakra-ui/react';
import { ReactElement, useState } from 'react';
import Joi from 'joi';
import { AutocompleteField, Form, InputField } from 'components/common/Form';
import { FieldError } from 'react-hook-form';
import { caughtErrorGlobally } from 'utils/axios';
import { AxiosError } from 'axios';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { ToastVariant } from 'types/Toast';
import { errorMessage } from 'components/common/Form/ErrorMessages';
import { useWelcomePostStep } from 'hooks/api/backend/welcome/useWelcomeStep';
import { NamePrefillData } from 'types/WelcomeScreen';
import { toastContent } from 'config/toast';
import { ErrorStatusType } from 'types/StatusTypes';
import { useCountryApi } from 'hooks/api/backend/useCountryApi';
import { AcademyIcon, CloseIcon, GlobeIcon } from 'assets/icons/Icons';
import { WelcomeScreenOptions } from './Welcome';

type NameValues = {
  firstName: string;
  lastName: string;
  countryId: string;
  country: string;
};

const schema = Joi.object({
  firstName: Joi.string().required().messages({
    'string.empty': errorMessage.empty,
  }),
  lastName: Joi.string().required().messages({
    'string.empty': errorMessage.empty,
  }),
  countryId: Joi.string().required().messages({
    '*': 'Du musst ein Land aus der Liste auswählen',
    'any.required': 'Du musst ein Land aus der Liste auswählen',
    'string.empty': 'Du musst ein Land aus der Liste auswählen',
  }),
  country: Joi.string().required().messages({
    '*': 'Du musst ein Land aus der Liste auswählen',
    'any.required': 'Du musst ein Land aus der Liste auswählen',
    'string.empty': 'Du musst ein Land aus der Liste auswählen',
  }),
});

export const UserInfo = (props: WelcomeScreenOptions): ReactElement => {
  const { onContinue, stepData } = props;
  const prefillData = stepData.currentData.prefillData as NamePrefillData;
  const toast = useNovaToast();
  const [isLoading, setLoading] = useState(false);
  const { mutateAsync: updateWelcomeStep } = useWelcomePostStep();
  const { getAutoCompleteItems } = useCountryApi();

  const handleSubmit = async (values: NameValues): Promise<void> => {
    setLoading(true);
    try {
      const onboardingData = await updateWelcomeStep({
        stepIdentifier: stepData.currentStepIdentifier,
        totalSteps: stepData.totalSteps,
        step: stepData.currentStep,
        data: {
          firstName: values.firstName,
          lastName: values.lastName,
          countryId: values.countryId,
        },
      });
      onContinue(onboardingData);
    } catch (error) {
      if (caughtErrorGlobally(error as AxiosError)) {
        toast.show(toastContent.error[ErrorStatusType.SERVER_ERROR]);
        return;
      }
      toast.show({
        variant: ToastVariant.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex width="100%" direction="column" gap="0px">
      <Text as="h1" textStyle="h2" color="blue.textHeader" mb="25px">
        Wer bist du?
      </Text>
      <Text textStyle="body.16.reg" color="grey.secondary" mb="25px">
        Gib uns noch ein paar Infos über dich.
      </Text>

      <Form<NameValues, typeof schema> onSubmit={handleSubmit} schema={schema} autoComplete="off">
        {({ register, formState, setValue, getValues, watch, resetField }) => {
          return (
            <>
              <Flex direction="column" gap="20px">
                <InputField
                  registration={register('firstName')}
                  placeholder="Vorname"
                  error={formState.errors.firstName as FieldError}
                  defaultValue={prefillData.firstName}
                />
                <InputField
                  registration={register('lastName')}
                  placeholder="Nachname"
                  error={formState.errors.lastName as FieldError}
                  defaultValue={prefillData.lastName}
                />

                <AutocompleteField
                  getAutocompleteItems={getAutoCompleteItems}
                  initalName={prefillData.country}
                  initalId={prefillData.countryId}
                  registrationName="country"
                  error={formState.errors.countryId as FieldError}
                  idName="countryId"
                  disableCreate
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  placeholder="Land oder Region"
                  leftElement={<GlobeIcon color={watch('country') ? 'green.primary' : 'grey.40'} />}
                  zIndex={1}
                  rightElement={
                    watch('country') && (
                      <Button
                        ml={4}
                        bg="none"
                        _hover={{ backgroundColor: 'none' }}
                        _active={{ backgroundColor: 'none' }}
                        _focus={{ outline: 'none' }}
                        onClick={() => {
                          resetField('country');
                          resetField('countryId');
                        }}
                      >
                        <Center bg="grey.40" rounded="full" boxSize="16px">
                          <CloseIcon color="bg.light" mt="3px" w="10px" m={0} />
                        </Center>
                      </Button>
                    )
                  }
                />
              </Flex>

              <Button
                translate="no"
                variant="primary"
                type="submit"
                isLoading={isLoading}
                loadingText="Speichern"
                mt="40px"
                w="full"
                py="10px"
              >
                Weiter
              </Button>
            </>
          );
        }}
      </Form>
    </Flex>
  );
};
