import React, { ReactElement } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { htmlToReactOptions } from 'utils/htmlToReactOptions';
import { QuestionSection, HtmlTagNames } from '@novaheal/types';
import { LightBulbIcon } from 'assets/icons/Icons';
import parse from 'html-react-parser';
import { ArticleSection } from './ArticleSection';

type Props = {
  questionSections: QuestionSection[];
};

export const ArticleQuestionSections = ({ questionSections }: Props): ReactElement => {
  const QuestionSections = questionSections as QuestionSection[];

  return (
    <>
      {QuestionSections.map((section: QuestionSection) => {
        const hasVideo: boolean = section.answer.includes(HtmlTagNames.VIDEO);
        return (
          <ArticleSection title={section.question} key={section.id} hasVideo={hasVideo}>
            {parse(section.answer, htmlToReactOptions)}
            <Box mt={2} />
            <HStack spacing={4} fontSize="14px">
              {section.operator && (
                <>
                  <LightBulbIcon w="40px" h="40px" />
                  <Box>
                    <Text as="span" color="system.error" mr="1ch" fontStyle="italic">
                      Operatoren Frage:
                    </Text>
                    <Text as="span">{section.operator}</Text>
                  </Box>
                </>
              )}
            </HStack>
          </ArticleSection>
        );
      })}
    </>
  );
};
