import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Breadcrumb as BreadcrumbComponent,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { ReactElement, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb } from 'types/breadcrumbs';
import { ProtectedRoutes, RouteType } from 'types/Routes';
import { useCurrentRoute } from '../../../hooks/utils/useCurrentRoute';
import { BreadcrumbContext } from '../../providers/BreadcrumbProvider';

export const crumbs: Breadcrumb[] = [
  {
    path: `${ProtectedRoutes.BASE}`,
    name: 'Home',
  },
  {
    path: `${ProtectedRoutes.NEWS}`,
    name: 'Novaheal News',
  },
  {
    path: ProtectedRoutes.KNOWLEDGE,
    name: 'Kategorien',
  },
  {
    path: ProtectedRoutes.SETTINGS,
    name: 'Profile',
  },
  {
    path: ProtectedRoutes.PROFILE,
    name: 'Persönliche Daten',
  },
  {
    path: ProtectedRoutes.PASSWORD,
    name: 'Passwort ändern',
  },
  {
    path: ProtectedRoutes.LEGAL,
    name: 'Rechtliches',
  },
  {
    path: ProtectedRoutes.SUPPORT,
    name: 'Support',
  },
  {
    path: ProtectedRoutes.PAYMENT_MANAGEMENT,
    name: 'Premium Management',
  },
  {
    path: ProtectedRoutes.VOUCHER,
    name: 'Code eingeben',
  },
  {
    path: ProtectedRoutes.REFERRAL,
    name: 'Freundschaftscode',
  },
];

export const Breadcrumbs = (): ReactElement | null => {
  const location = useLocation();
  const { additionalBreadcrumbs, showBreadcrumbs } = useContext(BreadcrumbContext);
  const { routeType } = useCurrentRoute(location);

  const isNotDefinedRoute = routeType === RouteType.NOT_DEFINED;
  const customCrumbs = additionalBreadcrumbs.length > 0 ? additionalBreadcrumbs : [];
  const filteredBreadcrumbs = crumbs.filter(
    (crumb: Breadcrumb) => crumb.path && location.pathname.includes(crumb.path)
  );
  const currentBreadcrumbs = [...filteredBreadcrumbs, ...customCrumbs];
  const shouldShowCrumbs = currentBreadcrumbs.length > 1 && showBreadcrumbs && !isNotDefinedRoute;

  return shouldShowCrumbs ? (
    <BreadcrumbComponent
      separator={<ChevronRightIcon color="blue.highlighted" />}
      p="30px 20px"
      textStyle="body.14.reg"
      overflowX="hidden"
    >
      {currentBreadcrumbs.map(
        (route: Breadcrumb) =>
          route.name &&
          route.path && (
            <BreadcrumbItem key={route.path}>
              <BreadcrumbLink
                as={Link}
                to={route.path}
                color={route.path === location.pathname ? 'blue.textHeader' : 'blue.highlighted'}
                whiteSpace="nowrap"
                overflow="hidden"
                maxW="220px"
                textOverflow="ellipsis"
              >
                {route.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )
      )}
    </BreadcrumbComponent>
  ) : null;
};
