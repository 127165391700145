import { ReactElement, useEffect, Suspense } from 'react';
import {
  useRoutes,
  Navigate,
  RouteObject,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useUser } from 'utils/auth';
import { Welcome } from 'pages/welcome/Welcome';
import { IndexRoutes, ProtectedRoutes, PublicRoutes } from 'types/Routes';
import { NotFound } from 'pages/landing/NotFound';
import { PageSpinner } from 'components/atoms/PageSpinner';
import { lazyImport } from 'utils/lazyimport';
import { Login } from 'pages/auth/Login';
import { Registration } from 'pages/auth/Registration';
import { ForgotPassword } from 'pages/auth/ForgotPassword';
import { ResetPassword } from 'pages/auth/ResetPassword';
import { Article } from 'pages/in-app/knowledge/Article';
import { Landing } from 'pages/landing/Landing';
import { useGlobalLinkInteractions } from 'hooks/useGlobalLinkInteractions';
import { SessionStorageKey, useSessionStorage } from 'hooks/useSessionStorage';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { toastContent } from 'config/toast';
import { SuccessStatusType } from 'types/StatusTypes';
import { B2BDashboard } from 'pages/in-app/B2BDashboard';

// Lazy Imports

const { Library } = lazyImport(() => import('../pages/in-app/knowledge/Library'), 'Library');
const { Home } = lazyImport(() => import('../pages/in-app/home/Home'), 'Home');
const { CuratedList } = lazyImport(() => import('../pages/in-app/home/CuratedList'), 'CuratedList');
const { Category } = lazyImport(() => import('../pages/in-app/knowledge/Category'), 'Category');
const { Favorites } = lazyImport(() => import('../pages/in-app/Favorites'), 'Favorites');
const { SearchResults } = lazyImport(
  () => import('../pages/in-app/SearchResults'),
  'SearchResults'
);
const { Settings } = lazyImport(() => import('../pages/in-app/settings/Settings'), 'Settings');
const { Profile } = lazyImport(() => import('../pages/in-app/settings/Profile'), 'Profile');
const { Voucher } = lazyImport(() => import('../pages/in-app/settings/Voucher'), 'Voucher');
const { Referral } = lazyImport(() => import('../pages/in-app/settings/Referral'), 'Referral');
const { Legal } = lazyImport(() => import('../pages/in-app/settings/Legal'), 'Legal');
const { Support } = lazyImport(() => import('../pages/in-app/settings/Support'), 'Support');
const { Folder } = lazyImport(() => import('../pages/in-app/knowledge/Folder'), 'Folder');
const { ChangePassword } = lazyImport(
  () => import('../pages/in-app/settings/ChangePassword'),
  'ChangePassword'
);
const { Medication } = lazyImport(
  () => import('../pages/in-app/knowledge/Medication'),
  'Medication'
);
const { PaymentRedirect } = lazyImport(
  () => import('../pages/in-app/subscription/PaymentRedirect'),
  'PaymentRedirect'
);
const { NewsOverviewPage } = lazyImport(
  () => import('../pages/in-app/home/NewsOverviewPage'),
  'NewsOverviewPage'
);
const { NewsPage } = lazyImport(() => import('../pages/in-app/home/NewsPage'), 'NewsPage');
const { PremiumManagement } = lazyImport(
  () => import('../pages/in-app/subscription/PremiumManagement'),
  'PremiumManagement'
);
const { Survey } = lazyImport(() => import('../pages/in-app/settings/Survey'), 'Survey');

const { DeleteAccount } = lazyImport(
  () => import('../pages/in-app/settings/DeleteAccount'),
  'DeleteAccount'
);

const { CancelSubscription } = lazyImport(
  () => import('../pages/in-app/settings/CancelSubscription'),
  'CancelSubscription'
);

let showedToast = false;

export const AppRoutes = (): ReactElement | null => {
  const { handleLinkInteractions } = useGlobalLinkInteractions();
  const { data: user } = useUser();

  const [params] = useSearchParams();
  const toast = useNovaToast();
  useEffect(() => {
    if (params.get('emailVerified') === 'true' && !showedToast) {
      toast.show(toastContent.success[SuccessStatusType.EMAIL_VERIFICATION]);
      showedToast = true;
    }
  }, [toast, params]);

  useEffect(() => {
    if (user && !user.isFirstTimeUse) {
      handleLinkInteractions();
    }
  }, [user, handleLinkInteractions]);

  const routes: RouteObject[] = [
    {
      path: PublicRoutes.BASE,
      element: <PublicRouteGuard />,
      children: [
        { path: PublicRoutes.LOGIN, element: <Login /> },
        { path: PublicRoutes.REGISTER, element: <Registration /> },
        { path: PublicRoutes.FORGOT_PASSWORD, element: <ForgotPassword /> },
        { path: PublicRoutes.RESET_PASSWORD, element: <ResetPassword /> },
        { path: PublicRoutes.BASE, element: <Landing /> },
        { path: '*', element: <NotFound /> },
      ],
    },
    {
      path: IndexRoutes.BASE,
      element: <IndexRouteGuard />,
      children: [{ path: IndexRoutes.FIRST_TIME, element: <Welcome /> }],
    },
    {
      path: ProtectedRoutes.BASE,
      element: (
        <Suspense fallback={<PageSpinner />}>
          <PrivateRouteGuard />
        </Suspense>
      ),
      children: [
        {
          path: ProtectedRoutes.NOT_FOUND_FALLBACK,
          element: <NotFound buttonText="Zurück zur App" buttonLink={ProtectedRoutes.BASE} />,
        },
        { path: ProtectedRoutes.HOME, element: <Home /> },
        { path: `${ProtectedRoutes.CURATED_LIST}/:id`, element: <CuratedList /> },
        { path: ProtectedRoutes.NEWS, element: <NewsOverviewPage /> },
        { path: `${ProtectedRoutes.NEWS}/:id`, element: <NewsPage /> },
        { path: ProtectedRoutes.KNOWLEDGE, element: <Library /> },
        { path: ProtectedRoutes.SEARCH, element: <SearchResults /> },
        { path: `${ProtectedRoutes.ARTICLE}/:id`, element: <Article /> },
        { path: `${ProtectedRoutes.MEDICATION}/:id`, element: <Medication /> },
        { path: `${ProtectedRoutes.FOLDER}/:id`, element: <Folder /> },
        { path: `${ProtectedRoutes.CATEGORY}/:id`, element: <Category /> },
        { path: ProtectedRoutes.FAVORITES, element: <Favorites /> },
        { path: ProtectedRoutes.SETTINGS, element: <Settings /> },
        { path: ProtectedRoutes.PROFILE, element: <Profile /> },
        { path: ProtectedRoutes.PASSWORD, element: <ChangePassword /> },
        { path: ProtectedRoutes.VOUCHER, element: <Voucher /> },
        { path: ProtectedRoutes.PAYMENT_MANAGEMENT, element: <PremiumManagement /> },
        { path: ProtectedRoutes.PAYMENT_REDIRECT, element: <PaymentRedirect /> },
        { path: ProtectedRoutes.LEGAL, element: <Legal /> },
        { path: ProtectedRoutes.SUPPORT, element: <Support /> },
        { path: ProtectedRoutes.SURVEY, element: <Survey /> },
        { path: ProtectedRoutes.DELETE_ACCOUNT, element: <DeleteAccount /> },
        { path: ProtectedRoutes.CANCEL_SUBSCRIPTION, element: <CancelSubscription /> },
        { path: ProtectedRoutes.REFERRAL, element: <Referral /> },
        { path: ProtectedRoutes.B2B_DASHBOARD, element: <B2BDashboard /> },
      ],
    },
  ];

  const element = useRoutes(routes);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{element}</>;
};

const PrivateRouteGuard = (): ReactElement | null => {
  const { data: user } = useUser();
  const location = useLocation();
  const { handleLinkInteractions, handleLinkExtractionInteraction } = useGlobalLinkInteractions();
  const deferredUrlStorage = useSessionStorage<string>(SessionStorageKey.DEFERRED_URL);
  const didLogoutStorage = useSessionStorage<boolean>(SessionStorageKey.DID_USER_LOGOUT);

  if (!user) {
    deferredUrlStorage.setValue(window.location.pathname + window.location.search);
    handleLinkExtractionInteraction();
    return <Navigate to={PublicRoutes.BASE} />;
  }

  if (user.isFirstTimeUse) {
    return <Navigate to={IndexRoutes.FIRST_TIME} />;
  }

  const didUserLogout = !!didLogoutStorage.getValue();
  const deferredUrl = deferredUrlStorage.getValue();

  const isRedirectToSettingAfterLogout =
    didUserLogout && deferredUrl && deferredUrl.includes(ProtectedRoutes.SETTINGS);

  if (deferredUrl && deferredUrl !== location.pathname && !isRedirectToSettingAfterLogout) {
    deferredUrlStorage.deleteItem();
    handleLinkInteractions();
    return <Navigate to={deferredUrl} />;
  }

  didLogoutStorage.deleteItem();
  deferredUrlStorage.deleteItem();
  return <Outlet />;
};

const PublicRouteGuard = (): ReactElement => {
  const { data: user } = useUser();
  const deferredUrlStorage = useSessionStorage<string>(SessionStorageKey.DEFERRED_URL);
  const didLogoutStorage = useSessionStorage<boolean>(SessionStorageKey.DID_USER_LOGOUT);

  if (user) {
    const didUserLogout = !!didLogoutStorage.getValue();
    const deferredUrl = deferredUrlStorage.getValue();

    const isRedirectToSettingAfterLogout =
      didUserLogout && deferredUrl && deferredUrl.includes(ProtectedRoutes.SETTINGS);

    return (
      <Navigate
        to={!isRedirectToSettingAfterLogout && deferredUrl ? deferredUrl : ProtectedRoutes.BASE}
      />
    );
  }

  return <Outlet />;
};

const IndexRouteGuard = (): ReactElement | null => {
  const { data: user, isFetched } = useUser();
  const didLogoutStorage = useSessionStorage<boolean>(SessionStorageKey.DID_USER_LOGOUT);
  const deferredUrlStorage = useSessionStorage<string>(SessionStorageKey.DEFERRED_URL);

  // prevent screen flashing when user data is not fetched yet
  if (!isFetched) {
    return null;
  }

  if (user && !user.isFirstTimeUse) {
    const deferredUrl = deferredUrlStorage.getValue();
    const didUserLogout = !!didLogoutStorage.getValue();
    const isRedirectToSettingAfterLogout =
      didUserLogout && deferredUrl && deferredUrl.includes(ProtectedRoutes.SETTINGS);

    return (
      <Navigate
        to={!isRedirectToSettingAfterLogout && deferredUrl ? deferredUrl : ProtectedRoutes.BASE}
      />
    );
  }

  return <Outlet />;
};
