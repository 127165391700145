import {
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  InputProps,
  TypographyProps,
} from '@chakra-ui/react';
import { ReactElement } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps &
  InputProps & {
    type?: 'text' | 'email' | 'password' | 'date';
    className?: string;
    placeholder?: string;
    registration: Partial<UseFormRegisterReturn>;
    rightElement?: React.ReactNode;
    leftElement?: React.ReactNode;
    isDisabled?: boolean;
    labelPosition?: TypographyProps['textAlign'];
  };

export const InputField = (props: InputFieldProps): ReactElement => {
  const {
    type,
    label,
    className,
    registration,
    error,
    placeholder,
    rightElement,
    leftElement,
    isDisabled = false,
    labelPosition,
  } = props;
  return (
    <FieldWrapper label={label} error={error} labelPosition={labelPosition}>
      <InputGroup>
        {leftElement && <InputLeftElement pointerEvents="none">{leftElement}</InputLeftElement>}
        <Input
          {...props}
          type={type}
          textOverflow="ellipsis"
          className={className}
          isDisabled={isDisabled}
          {...registration}
          placeholder={placeholder}
          bg="bg.input"
          focusBorderColor="green.primary"
        />
        {rightElement && <InputRightElement width="4.5rem">{rightElement}</InputRightElement>}
      </InputGroup>
    </FieldWrapper>
  );
};
