import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ToastVariant } from 'types/Toast';
import { axios } from 'utils/axios';

type Notification = {
  notificationId: string;
  title: string;
  description: string;
  variant: ToastVariant;
};

const getNotifications = async (): Promise<Notification[]> => {
  try {
    const { data: notification } = await axios.get<Notification[]>(`/notification`);
    return notification;
  } catch (error) {
    return [];
  }
};

export const useNotifications = (): UseQueryResult<Notification[], unknown> => {
  return useQuery({
    queryKey: ['get-notifications'],
    queryFn: getNotifications,
    retry: 1,
  });
};
