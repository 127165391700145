import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const ImageIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 4.8335V20.1668H8.26202L14.4539 11.9493C15.0506 11.1573 16.2028 11.0584 16.9258 11.7373L21.5 16.0322V4.8335H2.5ZM21.5 20.1668H10.1402L15.6518 12.852C15.7115 12.7728 15.8267 12.7629 15.899 12.8308L21.5 18.0898V20.1668ZM23 20.7502V17.4675L23.0134 17.4532L23 17.4407V4.25016C23 3.7439 22.5896 3.3335 22.0833 3.3335H1.91667C1.41041 3.3335 1 3.7439 1 4.25016V20.7502C1 21.2564 1.4104 21.6668 1.91667 21.6668H22.0833C22.5896 21.6668 23 21.2564 23 20.7502ZM9.07016 9.97502C9.07016 10.9663 8.26658 11.7699 7.27532 11.7699C6.28405 11.7699 5.48047 10.9663 5.48047 9.97502C5.48047 8.98376 6.28405 8.18018 7.27532 8.18018C8.26658 8.18018 9.07016 8.98376 9.07016 9.97502ZM10.5702 9.97502C10.5702 11.7947 9.09501 13.2699 7.27532 13.2699C5.45562 13.2699 3.98047 11.7947 3.98047 9.97502C3.98047 8.15533 5.45562 6.68018 7.27532 6.68018C9.09501 6.68018 10.5702 8.15533 10.5702 9.97502Z"
        fill="currentColor"
      />
    </Icon>
  );
};
