import { AxiosError } from 'axios';
import { useUser } from 'utils/auth';
import { axios, caughtErrorGlobally } from 'utils/axios';
import { AutocompleteItem } from 'types/autocompleteItem';
import { EducationStatus } from '@novaheal/types';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { ToastVariant } from 'types/Toast';
import { toastContent } from 'config/toast';
import { ErrorStatusType, UserUpdateStatusType } from 'types/StatusTypes';

// TODO: Add proper types
export const useUserApi = (): {
  updateSchoolOfUser: (item: AutocompleteItem, educationStatus: EducationStatus) => Promise<any>;
  updateFirstTimeUse: () => Promise<any>;
  updateOnboardingModal: () => Promise<any>;
  updateOnboardingVideo: () => Promise<any>;
} => {
  const toast = useNovaToast();
  const { refetch: refetchUser } = useUser();

  const updateSchoolOfUser = async (
    item: AutocompleteItem,
    educationStatus: EducationStatus
  ): Promise<any> => {
    try {
      const resp = await axios.patch('/user/school', {
        b2cSchoolData: {
          ...item,
        },
        educationStatus,
      });
      if (resp.status === 200) {
        return resp.data;
      }
    } catch (error) {
      if (caughtErrorGlobally(error as AxiosError)) {
        toast.show(toastContent.error[ErrorStatusType.SERVER_ERROR]);
        return;
      }
      const axiosError = error as AxiosError;
      if (axiosError?.response?.status === 422) {
        toast.show(toastContent.user[UserUpdateStatusType.SCHOOL_NAME_AS_VOUCHER]);
      } else
        toast.show({
          variant: ToastVariant.ERROR,
        });
    }
  };

  const updateFirstTimeUse = async (): Promise<any> => {
    try {
      const resp = await axios.patch('/user/first-time');
      if (resp.status === 200) {
        refetchUser();
        return resp.data;
      }
    } catch (error) {
      if (caughtErrorGlobally(error as AxiosError)) {
        toast.show(toastContent.error[ErrorStatusType.SERVER_ERROR]);
        return;
      }

      toast.show({
        variant: ToastVariant.ERROR,
      });
    }
  };

  // TODO: rethink the return type
  // TODO: add error handling
  const updateOnboardingModal = async () => {
    try {
      const resp = await axios.patch('/user/onboarding-modal');
      if (resp.status === 200) {
        await refetchUser();
        return resp.data;
      }
    } catch (error) {
      if (!caughtErrorGlobally(error as AxiosError)) {
        toast.show({
          variant: ToastVariant.ERROR,
        });
      }
    }
  };

  const updateOnboardingVideo = async () => {
    try {
      const resp = await axios.patch('/user/onboarding-video');
      if (resp.status === 200) {
        await refetchUser();
        return resp.data;
      }
    } catch (error) {
      if (!caughtErrorGlobally(error as AxiosError)) {
        toast.show({
          variant: ToastVariant.ERROR,
        });
      }
    }
  };

  return {
    updateSchoolOfUser,
    updateFirstTimeUse,
    updateOnboardingModal,
    updateOnboardingVideo,
  };
};
