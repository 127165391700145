import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ModalVariant } from 'types/Modal';
import { useUser } from 'utils/auth';
import { PaymentPlatform, SubscriptionStatus } from '@novaheal/types';
import { toastContent } from 'config/toast';
import { PromoCode } from 'types/PromoCode';
import { useModalService } from './useModalService';
import { useNovaToast } from './utils/useNovaToast';
import { SessionStorageKey, useSessionStorage } from './useSessionStorage';

type Discount = {
  amount: number;
  unit: 'percent' | 'currency';
};

let hasShownRefToast = false;

const extractDiscountInfo = (discountStr: string): Discount | null => {
  const regex = /(\d+)([%€])/;
  const match = discountStr.match(regex);

  if (match) {
    const amount = parseInt(match[1], 10);
    let unit: 'percent' | 'currency';

    switch (match[2]) {
      case '%':
        unit = 'percent';
        break;
      case '€':
        unit = 'currency';
        break;
      default:
        return null; // In case of unexpected symbol
    }

    return { amount, unit };
  }

  return null;
};

const extractPackageType = (packageType: string | null): 'yearly' | 'monthly' | undefined => {
  if (!packageType) return undefined;

  switch (packageType) {
    case 'yearly':
      return 'yearly';
    case 'monthly':
      return 'monthly';
    default:
      return undefined;
  }
};

export const useGlobalLinkInteractions = (): {
  handleLinkInteractions: () => void;
  handleLinkExtractionInteraction: () => void;
} => {
  const location = useLocation();
  const navigate = useNavigate();
  const modalService = useModalService();
  const [handledParams, setHandledParams] = useState<{ [key: string]: boolean }>({});
  const { data: user } = useUser();
  const toastService = useNovaToast();
  const promoStorage = useSessionStorage<PromoCode>(SessionStorageKey.PROMO_CODE);
  const refIdStorage = useSessionStorage<string>(SessionStorageKey.REF_ID);
  const deferredStorage = useSessionStorage<string>(SessionStorageKey.DEFERRED_URL);

  const handleLinkInteractions = useCallback(() => {
    const url = new URL(location.pathname + location.search, window.location.origin);
    const searchParams = new URLSearchParams(url.search);
    const newHandledParams = { ...handledParams };

    // Check for specific parameters and implement your logic
    if (searchParams.get('modal') === 'checkout') {
      if (
        user?.subscription?.paymentPlatform !== PaymentPlatform.STRIPE &&
        user?.subscription?.status === SubscriptionStatus.PAID
      ) {
        toastService.show({
          ...toastContent.subscription.checkoutError,
        });
        return;
      }

      const promoCodeId = searchParams.get('promoCodeId');
      const promoCodeValueString = searchParams.get('promoCodeValue');
      const promoCodeExpirationDate = searchParams.get('promoCodeExpirationDate');
      const promoCodeRedemptionLimit = searchParams.get('promoCodeRedemptionLimit');
      const packageString = searchParams.get('package');

      // get unit & amount from a string like these: '10% off' or '10€ off'
      const promoCodeValue = promoCodeValueString
        ? extractDiscountInfo(promoCodeValueString)
        : null;

      const packageType = extractPackageType(packageString);

      const promoCode = {
        id: promoCodeId,
        value: promoCodeValue,
        expirationDate: promoCodeExpirationDate,
        maxRedemptions: promoCodeRedemptionLimit ? parseInt(promoCodeRedemptionLimit, 10) : null,
        package: packageType,
      };

      promoStorage.setValue(promoCode);

      const isCouponExpired = promoCode.expirationDate
        ? new Date(promoCode.expirationDate) < new Date()
        : false;

      if (isCouponExpired) {
        toastService.show({
          ...toastContent.subscription.couponValidations.couponExpired,
        });
      }

      modalService.show(ModalVariant.SUBSCRIBE);
      searchParams.delete('modal');
      searchParams.delete('promoCodeId');
      searchParams.delete('promoCodeValue');
      searchParams.delete('promoCodeExpirationDate');
      searchParams.delete('promoCodeRedemptionLimit');
      searchParams.delete('package');

      newHandledParams.modal = true;
    }

    if (JSON.stringify(newHandledParams) !== JSON.stringify(handledParams)) {
      navigate(`${url.pathname}?${searchParams.toString()}`, { replace: true });
      setHandledParams(newHandledParams);
    }
  }, [location, modalService, navigate, handledParams, user, toastService, promoStorage]);

  const handleLinkExtractionInteraction = useCallback(() => {
    const deferredUrl = deferredStorage.getValue();
    if (!deferredUrl) return;
    const parsedUrl = new URL(deferredUrl, window.location.origin);
    const urlSearchParams = parsedUrl.searchParams;

    // RefId handling
    const refId = urlSearchParams.get('refId');
    const refExpiration = urlSearchParams.get('refExpiration');
    const isExpired = refExpiration ? new Date(refExpiration) < new Date() : false;

    if (isExpired && !hasShownRefToast) {
      toastService.show({
        ...toastContent.referral.invitationExpired,
      });
      hasShownRefToast = true;
    }

    if (refId && !isExpired) {
      refIdStorage.setValue(refId);
    }

    // Coupon handling if user is not logged in
    const couponExpirationDate = urlSearchParams.get('promoCodeExpirationDate');
    const isCouponExpired = couponExpirationDate
      ? new Date(couponExpirationDate) < new Date()
      : false;
    const hasPromoCode = !!urlSearchParams.get('promoCodeId');
    if (hasPromoCode && !isCouponExpired) {
      toastService.show({
        ...toastContent.subscription.promoCodeRegisterFlow,
      });
    }

    if (hasPromoCode && isCouponExpired) {
      toastService.show({
        ...toastContent.subscription.couponValidations.couponExpired,
      });
    }
  }, [toastService, deferredStorage, refIdStorage]);

  return { handleLinkInteractions, handleLinkExtractionInteraction };
};
