import { EmbeddedEntryType, isEmbeddedEntryOfType } from '@novaheal/types';
import { useEntry } from 'hooks/api/backend/getEntryById';
import React, { ReactElement } from 'react';
import { ArticleVideo } from './ArticleVideo';

type Props = {
  id: string;
};

export const ArticleEmbeddedEntry = ({ id }: Props): ReactElement | null => {
  const { data: entry } = useEntry(id);

  if (isEmbeddedEntryOfType(entry, EmbeddedEntryType.VIDEO)) {
    return (
      <ArticleVideo
        rawVideoUrl={entry.fields.url}
        description={entry.fields.description}
        isEmbedded
        thumbnailUrl={entry.fields.thumbnailUrl}
      />
    );
  }
  return null;
};
