import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Grid,
  Spinner,
} from '@chakra-ui/react';
import { ReactElement } from 'react';

type UserData = {
  actions: JSX.Element | null;
  b2bSchoolId: string;
  educationStatus: string;
  email: string;
  firstName: string;
  id: string;
  subscriptionStatus: string;
  subscriptionUpdatedAt: string;
  voucherId: string;
  voucherShortCode: string;
};
export type Column = {
  header: string;
  accessor: keyof UserData;
};

type Props = {
  columns: Column[];
  data: UserData[];
  isDataLoading: boolean;
};

export const Table = ({ columns, data, isDataLoading }: Props): ReactElement => {
  return (
    <Box width="100%">
      <TableContainer>
        <ChakraTable variant="simple">
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={column.accessor}>{column.header}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody width="100%">
            {isDataLoading ? (
              <Tr>
                <Td colSpan={columns.length}>
                  <Grid placeItems="center" width="100%">
                    <Spinner
                      m="10%"
                      thickness="7px"
                      speed="1s"
                      emptyColor="grey.10"
                      color="green.primary"
                      size="xl"
                    />
                  </Grid>
                </Td>
              </Tr>
            ) : (
              data?.map((row, rowIndex) => (
                <Tr
                  key={`row-${row.id}` || `row-${rowIndex}`}
                  _hover={{
                    bg: 'gray.50',
                  }}
                >
                  {columns.map((column) => (
                    <Td key={column.accessor}>{row[column.accessor]}</Td>
                  ))}
                </Tr>
              ))
            )}
          </Tbody>
        </ChakraTable>
      </TableContainer>
    </Box>
  );
};
