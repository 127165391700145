import React, { ReactElement, useEffect, useState } from 'react';
import { PageContext } from 'components/common/PageContext';
import { Flex } from '@chakra-ui/react';
import { ProgressBar } from 'components/Welcome/ProgressBar';
import { useUserApi } from 'hooks/api/backend/useUserApi';
import { PageSpinner } from 'components/atoms/PageSpinner';
import { useWelcomeGetStep } from 'hooks/api/backend/welcome/useWelcomeStep';
import * as Sentry from '@sentry/react';
import { OnboardingStepIdentifier, WelcomeScreenGet } from 'types/WelcomeScreen';
import { useUser } from 'utils/auth';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { toastContent } from 'config/toast';
import { EducationInfo } from './EducationInfo';
import { ReferralSurvey } from './ReferralSurvey';
import { UserInfo } from './UserInfo';
import { Dob } from './Dob';

export type WelcomeScreenOptions = {
  onContinue: (stepData: WelcomeScreenGet) => void;
  stepData: WelcomeScreenGet;
};

let hasShownReferralToast = false;

export const Welcome = (): ReactElement | null => {
  const { data: user } = useUser();
  const toast = useNovaToast();
  const [onboardingData, setOnboardingData] = useState<WelcomeScreenGet | null>(null);
  const [queryOptions, setQueryOptions] = useState<{
    id?: OnboardingStepIdentifier;
    step?: number;
    totalSteps?: number;
  }>({});

  useEffect(() => {
    if (user?.referralCode && !hasShownReferralToast) {
      toast.show(toastContent.referral.redeemSuccessWelcome);
      hasShownReferralToast = true;
    }
  }, [user?.referralCode, toast]);

  const { updateFirstTimeUse } = useUserApi();
  const { data: onboardingStep, isLoading } = useWelcomeGetStep(queryOptions);

  useEffect(() => {
    if (onboardingStep) {
      setOnboardingData(onboardingStep);
    }
  }, [onboardingStep]);

  const handleContinue = async (nextOnboardingData: WelcomeScreenGet): Promise<void> => {
    if (
      onboardingData?.currentStepIdentifier === OnboardingStepIdentifier.QUESTIONNAIRE_STEP ||
      nextOnboardingData?.currentStepIdentifier === null
    ) {
      await updateFirstTimeUse();
      return;
    }
    setOnboardingData(nextOnboardingData);
  };

  const handleBackClick = (): void => {
    if (!onboardingData?.previousStepIdentifier) return;
    setQueryOptions({
      id: onboardingData.previousStepIdentifier,
      step: onboardingData.currentStep - 1,
      totalSteps: onboardingData.totalSteps,
    });
  };

  if (onboardingData === null || isLoading) return <PageSpinner />;

  return (
    <PageContext
      hasBackButton={!!onboardingData.previousStepIdentifier}
      customOnBackClick={handleBackClick}
    >
      <Flex
        px="20px"
        py="60px"
        flex={1}
        gap="20px"
        flexDirection="column"
        width="100%"
        minHeight="100%"
      >
        {onboardingData.currentStep && (
          <>
            <ProgressBar
              currentStep={onboardingData.currentStep}
              stepCount={onboardingData.totalSteps}
              hasNonZeroIndex
            />

            <Flex flexGrow={1} flexDirection="column" justifyContent="center">
              {getDynamicContent({ onContinue: handleContinue, stepData: onboardingData })}
            </Flex>
          </>
        )}
      </Flex>
    </PageContext>
  );
};

const getDynamicContent = (options: WelcomeScreenOptions): ReactElement | null => {
  switch (options.stepData.currentStepIdentifier) {
    case OnboardingStepIdentifier.BIRTHDATE_STEP:
      return <Dob {...options} />;
    case OnboardingStepIdentifier.NAME_STEP:
      return <UserInfo {...options} />;
    case OnboardingStepIdentifier.EDUCATION_STEP:
      return <EducationInfo {...options} />;
    case OnboardingStepIdentifier.QUESTIONNAIRE_STEP:
      return <ReferralSurvey {...options} />;
    default:
      Sentry.captureException(new Error(`Invalid step: ${options.stepData.currentStepIdentifier}`));
      return null;
  }
};
