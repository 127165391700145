import { VStack, Box, Text } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { ReactElement, useEffect, useRef } from 'react';
import { IconType } from 'types/iconType';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useNavigate } from 'react-router-dom';
import { ProtectedRoutes } from 'types/Routes';
import { useDocumentHeight } from 'hooks/utils/layout/useDocumentHeight';
import { SearchContentType } from '@novaheal/types';
import { getIsLastIndex } from 'utils/getIsLastIndex';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import { Breakpoints } from 'types/breakpoints';
import { NoContentTextWrapper } from 'components/common/NoContentTextWrapper';
import { useHits } from 'react-instantsearch';
import { useAddSearchEvent } from 'hooks/api/backend/search/addSearchEvent';
import { SearchDropdownListItem } from './SearchDropdownListItem';

type ListItem = {
  text: string;
  id: string;
  contentType?: SearchContentType;
};

type SearchDropdownListProps = {
  listItems: ListItem[];
  isOpen: boolean;
  variant: IconType;
  onItemClick: (id: string, text: string) => void;
  onSeeMoreClick: () => void;
};

export const SearchDropdownList = ({
  listItems,
  isOpen,
  variant,
  onItemClick,
  onSeeMoreClick,
}: SearchDropdownListProps): ReactElement => {
  const { results } = useHits();
  const navigate = useNavigate();
  const { height } = useDocumentHeight();
  const resultListRef = useRef<HTMLDivElement>(null);
  const hasResults = listItems.length !== 0;
  const showMoreButton = listItems.length > 8 && variant === IconType.RECOMMEND;
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);
  const { mutateAsync: addSearchEvent } = useAddSearchEvent();

  const getListItems = (): ListItem[] => {
    return showMoreButton ? listItems.slice(0, 9) : listItems;
  };

  // This calculates the number of additional results that are not shown in the dropdown
  // The dropdown shows autocomplete recommendations, and our results differ from those. But as the number would be irritating for the user, we show the number of additional results
  const totalAdditionalResults = results
    ? results.hits.length - getListItems().length
    : listItems.length - getListItems().length;

  // only allow scrolling inside the modal
  useEffect(() => {
    if (!resultListRef.current) return;
    if (isOpen) {
      disableBodyScroll(resultListRef.current);
    } else {
      enableBodyScroll(resultListRef.current);
    }

    return () => {
      // Make sure to reenable scrolling when the component unmounts
      clearAllBodyScrollLocks();
    };
  }, [isOpen, resultListRef]);

  const onTopItemClick = (
    contentType: SearchContentType | undefined,
    id: string,
    text: string
  ): void => {
    addSearchEvent(text);
    if (!contentType || contentType === SearchContentType.VIDEO) onItemClick(id, text);
    else
      navigate(
        `${
          contentType === 'lernkarte' ? ProtectedRoutes.ARTICLE : ProtectedRoutes.MEDICATION
        }/${id}`
      );
  };

  const isTopItem = (index: number): boolean => index < 3;

  return (
    <Box
      ref={resultListRef}
      position="absolute"
      h={height}
      // hard coded base values since we gonna replace mobile variant anyway
      w={{ base: 'calc(100% + 24px)', md: '100%' }} // 👆🏼
      top={{ base: '52px', md: 'inherit' }} // 👆🏼
      left={{ base: '-12px', md: '0' }} // 👆🏼
      overflow="scroll"
      hidden={!isOpen}
      css={css`
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      `}
    >
      <Box
        w="full"
        minH={{ base: '100%', md: '0' }}
        bg="bg.light"
        border={{ md: '1px solid' }}
        borderTop={{ md: 'none' }}
        borderColor={{ md: 'grey.20' }}
        borderRadius={{ md: '0 0 10px 10px' }}
        overflow="hidden"
      >
        {hasResults ? (
          <VStack align="left" spacing={0}>
            {getListItems().map((item: ListItem, index, arr) => (
              <SearchDropdownListItem
                iconType={variant}
                text={item.text}
                key={item.id}
                contentType={item.contentType || undefined}
                isTopItem={isTopItem(index)}
                onTopItemClick={() => onTopItemClick(item.contentType, item.id, item.text)}
                onItemClick={() => onItemClick(item.id, item.text)}
                shouldHideDivider={getIsLastIndex(index, arr) && isDesktop && !showMoreButton}
              />
            ))}
            {showMoreButton && (
              <Text
                cursor="pointer"
                onClick={onSeeMoreClick}
                onMouseDown={onSeeMoreClick}
                color="blue.highlighted"
                p="8px 12px 8px 41px"
              >
                Mehr anzeigen({totalAdditionalResults})
              </Text>
            )}
          </VStack>
        ) : (
          <Box py="40px">
            <NoContentTextWrapper minHeight="0">
              Leider wurden keine Inhalte für &quot;{results?.query}&quot; gefunden.
            </NoContentTextWrapper>
          </Box>
        )}
      </Box>
    </Box>
  );
};
