import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { CONTENTFUL_IS_PREVIEW } from 'config/env';
import { Keyword } from '@novaheal/types';
import { axios } from 'utils/axios';

export const getKeyword = async (id: string | undefined): Promise<Keyword | null> => {
  const { data: keyword } = await axios.get<Keyword | null>(
    `/gql/keyword/${id}?preview=${CONTENTFUL_IS_PREVIEW}`
  );

  return keyword;
};

export const useKeyword = (id: string | undefined): UseQueryResult<Keyword, unknown> => {
  return useQuery({
    queryKey: [`keyword-${id}`],
    queryFn: () => getKeyword(id),
    enabled: !!id,
  });
};
