import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const ArrowCircleIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" r="11.3333" stroke="currentColor" strokeWidth="1.33333" fill="none" />
      <path
        d="M12.0021 7.8938C11.7582 7.8938 11.5491 7.98562 11.3679 8.1834L6.05756 13.6788C5.90425 13.8342 5.82064 14.0319 5.82064 14.265C5.82064 14.7312 6.18304 15.1055 6.64993 15.1055C6.87995 15.1055 7.08202 15.0067 7.24229 14.8513L12.0021 9.89981L16.7619 14.8513C16.9222 15.0067 17.1313 15.1055 17.3543 15.1055C17.8212 15.1055 18.1836 14.7312 18.1836 14.265C18.1836 14.0319 18.1 13.8342 17.9466 13.6788L12.6433 8.1834C12.4551 7.98562 12.246 7.8938 12.0021 7.8938Z"
        fill="currentColor"
      />
    </Icon>
  );
};
