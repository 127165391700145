import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

type Props = IconProps;

export const AddUserIcon = (props: Props): ReactElement => {
  return (
    <Icon viewBox="0 0 22 22" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3395 3.24609C8.5832 3.24609 7.08693 4.76717 7.08693 6.73559C7.08693 8.31867 8.05473 9.61242 9.34724 10.0583C9.45274 10.6573 9.63801 11.231 9.89264 11.7653L7.90625 11.8987C7.33476 12.066 6.44992 12.4846 5.65927 13.1735C4.84424 13.8837 4.17279 14.8411 3.99625 16.0608L2.51172 15.846C2.75315 14.1779 3.66875 12.9184 4.67386 12.0426C5.53924 11.2886 6.50331 10.7892 7.25459 10.5323C6.22862 9.6103 5.58693 8.24046 5.58693 6.73559C5.58693 4.02119 7.67467 1.74609 10.3395 1.74609C11.4542 1.74609 12.4752 2.14921 13.2804 2.81556C12.7557 3.0211 12.2683 3.29735 11.8268 3.63155C11.3791 3.38397 10.8733 3.24609 10.3395 3.24609Z"
        fill="currentColor"
      />
      <path
        d="M18.1669 15.7065V18.9281M18.1669 22.1497V18.9281M18.1669 18.9281H14.9453M18.1669 18.9281H21.3885"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2449 8.88988C12.2449 6.92147 13.7412 5.40039 15.4975 5.40039C17.2538 5.40039 18.75 6.92147 18.75 8.88988C18.75 10.8583 17.2538 12.3794 15.4975 12.3794C13.7412 12.3794 12.2449 10.8583 12.2449 8.88988ZM15.4975 3.90039C12.8327 3.90039 10.7449 6.17548 10.7449 8.88988C10.7449 10.4368 11.423 11.841 12.4995 12.7628C10.3613 13.4691 6.63912 16.1589 6.15234 22.0467L7.44792 22.1538C7.90007 16.6849 11.4095 14.4262 13.0468 13.9539L14.845 13.8332C15.0579 13.8636 15.2757 13.8794 15.4975 13.8794C18.1623 13.8794 20.25 11.6043 20.25 8.88988C20.25 6.17548 18.1623 3.90039 15.4975 3.90039Z"
        fill="currentColor"
      />
    </Icon>
  );
};
