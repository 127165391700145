import { PageSpinner } from 'components/atoms/PageSpinner';
import { useModalService } from 'hooks/useModalService';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import React, { useEffect, useState } from 'react';
import { Breakpoints } from 'types/breakpoints';
import { ModalTarget, ModalVariant } from 'types/Modal';
import { useUser } from 'utils/auth';

/**
 * Higher-Order Component (HOC) to enforce email verification for a wrapped React component.
 *
 * This HOC ensures that the user has a verified email before rendering the `WrappedComponent`.
 * If the user's email is not verified, it:
 * - Displays a modal prompting the user to verify their email.
 * - Shows a loading spinner (`PageSpinner`) until the verification status is resolved.
 *
 * **Key Features:**
 * - Ensures that only users with verified emails can access the wrapped component.
 * - Uses the `useModalService` hook to show a verification modal (`ModalVariant.VERIFICATION_WALL`).
 * - Displays a loading spinner while fetching user data or if the email is unverified.
 *
 * @template P - The props type of the wrapped component.
 *
 * @param {React.ComponentType<P>} WrappedComponent - The React component to be wrapped by the HOC.
 *
 * @returns {React.FC<P>} A new component that enforces email verification and renders the
 *                        `WrappedComponent` when the user’s email is verified.
 *
 * @example
 * const ProfilePage = (props) => <div>Welcome to your profile!</div>;
 *
 * const VerifiedProfilePage = withMailGuard(ProfilePage);
 *
 * export const App = () => <VerifiedProfilePage someProp="value" />;
 */
export const withMailGuard = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P> => {
  return (props: P) => {
    const { show: showModal } = useModalService();
    const [isDismissed, setIsDismissed] = useState(false);

    const isDesktop = useBreakpoint(Breakpoints.DESKTOP);

    const { data: user, isFetched, isLoading } = useUser();

    useEffect(() => {
      if (isFetched && !user?.emailVerified && !isDismissed) {
        showModal(ModalVariant.MAIL_VERIFICATION, {
          shouldGoBack: true,
          target: isDesktop ? ModalTarget.PAGE_DESKTOP : ModalTarget.PAGE_MOBILE,
          onClose: () => setIsDismissed(true),
        });
      }
    }, [showModal, user, isFetched, isDesktop, isDismissed]);

    if (isLoading || !isFetched || !user?.emailVerified) {
      return <PageSpinner />;
    }

    return <WrappedComponent {...props} />;
  };
};
