import { Text, VStack, Stack, Button, Box } from '@chakra-ui/react';
import Joi from 'joi';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'components/common/Form';
import { useLogin } from 'utils/auth';
import { PageContext } from 'components/common/PageContext';
import { errorMessage } from 'components/common/Form/ErrorMessages';
import { caughtErrorGlobally } from 'utils/axios';
import { AxiosError } from 'axios';
import { Separator } from 'components/common/Separator';
import { FieldError } from 'react-hook-form';
import { PublicRoutes } from 'types/Routes';
import { InAppLayoutVariant } from 'types/Layout';
import { PasswordInputField } from 'components/common/Form/PasswordInputField';
import { EmailInputField } from 'components/common/Form/EmailInputField';
import { GoogleButton } from 'components/atoms/GoogleButton';
import { AuthenticationType, EmailLoginCredentials } from '@novaheal/types';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { useFeatureFlagsContext } from 'hooks/utils/useFeatureFlagContext';
import { toastContent } from 'config/toast';
import { ErrorStatusType } from 'types/StatusTypes';
import { useSentry } from 'hooks/useSentry';
import { AppleButton } from 'components/atoms/AppleButton';

const schema = Joi.object({
  email: Joi.string()
    .email({
      tlds: {
        allow: false,
      },
    })
    .required()
    .messages({
      'string.empty': errorMessage.empty,
      'string.email': errorMessage.email,
    }),
  password: Joi.string().required().messages({
    'string.empty': errorMessage.empty,
  }),
});

type LoginInputs = {
  email: string;
  password: string;

  authenticateWithGoogle: boolean;
};

export const Login = (): ReactElement => {
  const login = useLogin();
  const featureFlags = useFeatureFlagsContext();
  const toast = useNovaToast();
  const { setUser: setSentryUser } = useSentry();

  const handleLogin = async (values: EmailLoginCredentials): Promise<void> => {
    try {
      const { email } = values;
      const user = await login.mutateAsync({
        ...values,
        authenticationType: AuthenticationType.EMAIL,
        email: email.toLowerCase(),
      });
      setSentryUser(user?.id);
    } catch (error) {
      if (caughtErrorGlobally(error as AxiosError)) {
        toast.show(toastContent.error[ErrorStatusType.SERVER_ERROR]);
        return;
      }
      toast.show(toastContent.error[ErrorStatusType.LOGIN]);
    }
  };

  return (
    <PageContext title="Login" headTitle="Login" variant={InAppLayoutVariant.TERTIARY}>
      <VStack spacing={10} w="full">
        <Stack spacing="30px" align="left" w="full" mt={10} px="20px">
          <VStack align="left" spacing="15px">
            <Text as="h1" textStyle="h1" color="blue.textHeader">
              Account einloggen
            </Text>
            <Text textStyle="body.16.reg" color="grey.secondary">
              Du hast noch keinen Account?{' '}
              <Link to={PublicRoutes.REGISTER}>
                <Text as="span" textStyle="body.16.med" color="blue.highlighted">
                  Jetzt registrieren
                </Text>
              </Link>
            </Text>
          </VStack>
          <Form<LoginInputs, typeof schema> onSubmit={handleLogin} schema={schema}>
            {({ register, formState, watch, resetField }) => (
              <>
                <EmailInputField
                  placeholder="Email"
                  error={formState.errors.email as FieldError}
                  register={() => register('email')}
                  value={watch('email')}
                  reset={() => resetField('email')}
                />

                <PasswordInputField
                  error={formState.errors.password as FieldError}
                  placeholder="Passwort"
                  register={() => register('password')}
                  value={watch('password')}
                  reset={() => resetField('password')}
                />

                <VStack align="self-end" mt="20px !important">
                  <Link to={PublicRoutes.FORGOT_PASSWORD}>
                    <Text textStyle="body.14.reg" ml="4px" as="span" color="blue.highlighted">
                      Passwort vergessen?
                    </Text>
                  </Link>
                </VStack>
                <Stack align="center" w="full">
                  <Button
                    variant="primary"
                    type="submit"
                    mt="30px"
                    w="full"
                    isDisabled={!formState.isValid}
                    isLoading={login.isPending}
                    translate="no"
                  >
                    Einloggen
                  </Button>
                </Stack>
              </>
            )}
          </Form>
        </Stack>
        <Box w="100%">
          <Separator text="Oder" />
        </Box>
        {featureFlags.isEnabled('isGoogleAuthEnabled') && (
          <Box px="20px" width="100%">
            <GoogleButton />
          </Box>
        )}
        {featureFlags.isEnabled('isAppleAuthEnabled') && (
          <Box px="20px" width="100%">
            <AppleButton />
          </Box>
        )}
      </VStack>
    </PageContext>
  );
};
