import React, { ReactElement } from 'react';
import { Button, Flex, ModalBody, Text } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { ModalAnnouncementVariantProps, SharedModalProps } from 'types/Modal';
import { useNavigate } from 'react-router-dom';
import { CustomModal } from '../CustomModal';

type Props = SharedModalProps & ModalAnnouncementVariantProps;

export const AnnouncementModal = ({
  isShown,
  title,
  externalLink,
  internalLink,
  message,
  handleClose: closeModal,
  onClose,
}: Props): ReactElement => {
  const navigate = useNavigate();
  const handleClose = (): void => {
    if (onClose) onClose();
    if (closeModal) closeModal();
  };

  return (
    <CustomModal
      isOpen={isShown}
      onClose={handleClose}
      title={title}
      isCentered
      maxWidth={{ base: '80%', md: '800px' }}
    >
      <ModalBody
        p={{ base: '15px 20px 40px', md: '25px 20px' }}
        css={css`
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
        `}
      >
        <Text textStyle="body.16.reg" whiteSpace="pre-wrap">
          {message}
        </Text>
        <Flex mt={4} justify="center" alignItems="center" flexDir="column" gap={4}>
          {internalLink && (
            <Button
              variant="primary"
              onClick={() => {
                navigate(internalLink.url);
                handleClose();
              }}
              width="fit-content"
              minWidth="200px"
            >
              {internalLink.label}
            </Button>
          )}
          {externalLink && (
            <Button
              variant={internalLink && externalLink ? 'secondary' : 'primary'}
              onClick={() => {
                window.open(externalLink.url, '_blank');
              }}
              width="fit-content"
              minWidth="200px"
            >
              {externalLink.label}
            </Button>
          )}
        </Flex>
      </ModalBody>
    </CustomModal>
  );
};
