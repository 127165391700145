import { Button, Image, Text, VStack } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import ErrorImage from 'assets/subscription/error.webp';
import serviceUnavailable from 'assets/common/serviceUnavailable.png';
import { AxiosError } from 'axios';
import { ExternalRoutes } from 'types/Routes';

export const renderError = ({ error, resetErrorBoundary }: FallbackProps): ReactElement => (
  <Error error={error} resetErrorBoundary={resetErrorBoundary} />
);

type ErrorBoundaryProps = Omit<FallbackProps, 'error'> & { error: AxiosError };

const Error = ({ error, resetErrorBoundary }: ErrorBoundaryProps): ReactElement => {
  const navigate = useNavigate();
  const [pathname, setPathname] = useState(window.location.pathname);

  const sanctionedMessageList = [
    'Your account is pending approval',
    'Your account has been rejected',
  ];
  const isSanctioned =
    error.response?.status === 403 &&
    sanctionedMessageList.includes((error.response?.data as { message: string })?.message);

  const handleReset = (): void => {
    resetErrorBoundary();
  };

  const handleNavigate = (): void => {
    navigate(-1);
    resetErrorBoundary();
  };

  useEffect(() => {
    if (window.location.pathname !== pathname) {
      resetErrorBoundary();
    }
    setPathname(window.location.pathname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetErrorBoundary, pathname, window.location.pathname]);

  return (
    <VStack spacing={5} px="20px" justifyContent="center" alignItems="center" width="100%">
      <Image src={isSanctioned ? serviceUnavailable : ErrorImage} />
      {isSanctioned ? (
        <>
          <Text textStyle="h1" align="center" color="blue.textHeader">
            Anmeldung nicht möglich
          </Text>
          <Text align="center" textStyle="h4.reg" color="grey.secondary">
            Einige notwendige Angaben in deinem Profil fehlen. Deshalb kann die Registrierung in
            unserer App nicht abgeschlossen werden. Bitte kontaktiere unser Support-Team, um das
            Problem zu lösen.
          </Text>
          <Button
            type="button"
            variant="primary"
            w="full"
            maxWidth="350px"
            as="a"
            href={ExternalRoutes.HELP}
            target="_blank"
            rel="noreferrer noopener"
          >
            Support kontaktieren
          </Button>
        </>
      ) : (
        <>
          <Text textStyle="h1" align="center" color="blue.textHeade">
            Oops!
          </Text>
          <Text align="center" textStyle="h4.reg" color="grey.secondary">
            Etwas ist schiefgelaufen. Bitte überprüfen Sie die Fehlermeldung in Rot unten:
          </Text>
          <Text align="center" textStyle="h5" color="system.error">
            {(error.response?.data as { message: string })?.message || error.message}
          </Text>
          <Button onClick={handleReset} type="button" variant="primary" w="full" maxWidth="350px">
            Versuchs noch Einmal
          </Button>
          <Button
            onClick={handleNavigate}
            type="button"
            variant="secondary"
            w="full"
            maxWidth="350px"
          >
            Zurück
          </Button>
        </>
      )}
    </VStack>
  );
};
