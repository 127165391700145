import { useEffect } from 'react';
import { toastContent } from 'config/toast';
import { useNovaToast } from './useNovaToast';

export const useGoogleTranslateDetection = (): void => {
  const toast = useNovaToast();

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const htmlEl = document.documentElement;
      if (
        htmlEl.classList.contains('translated-ltr') ||
        htmlEl.classList.contains('translated-rtl')
      ) {
        // Google Translate is active
        toast.show(toastContent.misc.translatorActive);
      }
    });

    observer.observe(document.documentElement, { attributes: true, attributeFilter: ['class'] });

    return () => observer.disconnect();
  }, [toast]);
};
